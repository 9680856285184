import axios from 'axios';
import { store } from '_redux/store';
import { LOG_OUT } from '_redux/types/auth.types';

const API_URL = process.env.REACT_APP_API_URL;

const apiInterceptor = axios.create({
  baseURL: API_URL,
});

apiInterceptor.interceptors.request.use(
  function (config) {
    const state = store.getState();
    const token = state?.auth?.accessToken;
    const resetTokenExpiry = state?.auth?.resetTokenExpiry;

    const currentTime = new Date().getTime();
    if (resetTokenExpiry && currentTime > resetTokenExpiry) {
      store.dispatch({ type: LOG_OUT });

      return Promise.reject(new Error('Session expired. Please log in again.'));
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiInterceptor;
