import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ADD_PAYOUT } from '_redux/types/payout.types';
import styles from './expenses.module.scss';
import { REPLACE_PAYOUT } from '_redux/types/payout.types';

const Expenses = ({
  open,
  onClose,
  selectedRows,
  limit,
  skip,
  period,
  month,
  year,
}) => {
  const dispatch = useDispatch();

  const [expenses, setExpenses] = useState([]);
  const [newExpense, setNewExpense] = useState({ name: '', amount: '' });

  const handleAddExpense = () => {
    if (newExpense.name && newExpense.amount) {
      setExpenses([
        ...expenses,
        {
          id: Date.now(),
          name: newExpense.name,
          amount: parseFloat(newExpense.amount),
          date: new Date().toISOString().split('T')[0], // Sets current date
        },
      ]);
      setNewExpense({ name: '', amount: '' });
    }
  };

  const handleRemoveExpense = id => {
    setExpenses(expenses.filter(expense => expense.id !== id));
  };

  const calculateTotal = () => {
    return expenses.reduce((acc, expense) => acc + expense.amount, 0);
  };

  const formatPayload = () => {
    return selectedRows.map(row => ({
      id: row.id,
      expenses: expenses.map(expense => ({
        name: expense.name,
        amount: expense.amount,
        date: expense.date,
      })),
    }));
  };

  const handleAddUpClick = () => {
    const payload = { data: formatPayload() };
    dispatch({ type: ADD_PAYOUT, payload, limit, skip, period, month, year });
    onClose();
  };

  const handleUpdateClick = () => {
    const payload = { data: formatPayload() };
    dispatch({
      type: REPLACE_PAYOUT,
      payload,
      limit,
      skip,
      period,
      month,
      year,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Manage Expenses
        </Typography>

        <Box display="flex" alignItems="center" gap={2} my={2}>
          <TextField
            label="Expense Name"
            value={newExpense.name}
            onChange={e =>
              setNewExpense({ ...newExpense, name: e.target.value })
            }
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextField
            label="Amount"
            value={newExpense.amount}
            onChange={e =>
              setNewExpense({ ...newExpense, amount: e.target.value })
            }
            variant="outlined"
            fullWidth
            size="small"
          />
          <Button
            onClick={handleAddExpense}
            variant="contained"
            sx={{
              minWidth: 'fit-content',
              background: '#003466',
              border: 'solid 1px #003466',
              ':hover': {
                background: 'white',
                border: 'solid 1px #003466',
                color: '#003466',
              },
            }}
          >
            Add
          </Button>
        </Box>

        <Box className={styles.expensesList}>
          {expenses.map(expense => (
            <Box
              key={expense.id}
              className={styles.expenseItem}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>{expense.name}</Typography>
              <Typography className={styles.expenseAmount}>
                £{expense.amount.toFixed(2)}
              </Typography>
              <IconButton
                onClick={() => handleRemoveExpense(expense.id)}
                size="small"
                sx={{ color: '#003466' }}
              >
                <Close />
              </IconButton>
            </Box>
          ))}
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box display="flex" justifyContent="space-between" my={2}>
          <Typography>Total</Typography>
          <Typography>£{calculateTotal().toFixed(2)}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            minWidth: 'fit-content',
            border: 'solid 1px #003466',
            color: '#003466',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAddUpClick}
          variant="contained"
          sx={{
            minWidth: 'fit-content',
            background: '#003466',
            border: 'solid 1px #003466',
            ':hover': {
              background: 'white',
              border: 'solid 1px #003466',
              color: '#003466',
            },
          }}
        >
          Add New
        </Button>
        <Button
          onClick={handleUpdateClick}
          variant="contained"
          sx={{
            minWidth: 'fit-content',
            background: '#003466',
            border: 'solid 1px #003466',
            ':hover': {
              background: 'white',
              border: 'solid 1px #003466',
              color: '#003466',
            },
          }}
        >
          Replace
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Expenses;
