import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  pendingEarlyPayments: [],
  approvedEarlyPayments: [],
  pendingRefundPayments: [],
  approvedRefundPayments: [],
  companyCommission: null,
  topPlans: [],
  topAdvisors: [],
  totalAdvisors: 0,
  productTypes: [],
  splitTypes: [],
  advisorBase: [],
  totalSales: null,
  loading: false,
  error: null,
  advisors: null,
  productsTypes: null,
  commissionTypes: null,
  margin: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setPendingEarlyPayments: (state, action) => {
      state.pendingEarlyPayments = action.payload;
    },
    setApprovedEarlyPayments: (state, action) => {
      state.approvedEarlyPayments = action.payload;
    },
    setPendingRefundPayments: (state, action) => {
      state.pendingRefundPayments = action.payload;
    },
    setApprovedRefundPayments: (state, action) => {
      state.approvedRefundPayments = action.payload;
    },
    setCompanyCommission: (state, action) => {
      state.companyCommission = action.payload;
    },
    setTopPlans: (state, action) => {
      state.topPlans = action.payload;
    },
    setTopAdvisors: (state, action) => {
      state.topAdvisors = action.payload;
    },
    setTotalAdvisors: (state, action) => {
      state.totalAdvisors = action.payload;
    },
    setProductTypes: (state, action) => {
      state.productTypes = action.payload;
    },
    setSplitTypes: (state, action) => {
      state.splitTypes = action.payload;
    },
    setAdvisorBase: (state, action) => {
      state.advisorBase = action.payload;
    },
    setTotalSales: (state, action) => {
      state.totalSales = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAdvisors: (state, action) => {
      state.advisors = action.payload;
    },
    setProductsTypes: (state, action) => {
      state.productsTypes = action.payload;
    },
    setCommissionTypes: (state, action) => {
      state.commissionTypes = action.payload;
    },
    setMargin: (state, action) => {
      state.margin = action.payload;
    },
  },
});

const parentSelector = state => state.dashboard;

const selectPendingEarlyPayments = createSelector(
  parentSelector,
  state => state.pendingEarlyPayments
);

const selectApprovedEarlyPayments = createSelector(
  parentSelector,
  state => state.approvedEarlyPayments
);

const selectPendingRefundPayments = createSelector(
  parentSelector,
  state => state.pendingRefundPayments
);

const selectApprovedRefundPayments = createSelector(
  parentSelector,
  state => state.approvedRefundPayments
);

const selectCompanyCommission = createSelector(
  parentSelector,
  state => state.companyCommission
);

const selectTopPlans = createSelector(parentSelector, state => state.topPlans);

const selectTopAdvisors = createSelector(
  parentSelector,
  state => state.topAdvisors
);

const selectTotalAdvisors = createSelector(
  parentSelector,
  state => state.totalAdvisors
);

const selectProductTypes = createSelector(
  parentSelector,
  state => state.productTypes
);

const selectSplitTypes = createSelector(
  parentSelector,
  state => state.splitTypes
);

const selectAdvisorBase = createSelector(
  parentSelector,
  state => state.advisorBase
);

const selectDashboardError = createSelector(
  parentSelector,
  state => state.error
);

const selectTotalSales = createSelector(
  parentSelector,
  state => state.totalSales
);

const selectMargin = createSelector(parentSelector, state => state.margin);

const selectAdvisors = createSelector(parentSelector, state => state.advisors);
const selectProductsTypes = createSelector(
  parentSelector,
  state => state.productsTypes
);
const selectCommissionTypes = createSelector(
  parentSelector,
  state => state.commissionTypes
);

const selectDashboardData = createSelector(parentSelector, state => ({
  pendingEarlyPayments: state.pendingEarlyPayments,
  approvedEarlyPayments: state.approvedEarlyPayments,
  pendingRefundPayments: state.pendingRefundPayments,
  approvedRefundPayments: state.approvedRefundPayments,
  companyCommission: state.companyCommission,
  topPlans: state.topPlans,
  topAdvisors: state.topAdvisors,
  totalAdvisors: state.totalAdvisors,
  productTypes: state.productTypes,
  splitTypes: state.splitTypes,
  advisorBase: state.advisorBase,
  totalSales: state.totalSales,
  advisors: state.advisors,
  productsTypes: state.productsTypes,
  commissionTypes: state.selectCommissionTypes,
  margin: state?.margin,
}));

export const selectors = {
  selectPendingEarlyPayments,
  selectApprovedEarlyPayments,
  selectPendingRefundPayments,
  selectApprovedRefundPayments,
  selectCompanyCommission,
  selectTopPlans,
  selectTopAdvisors,
  selectTotalAdvisors,
  selectProductTypes,
  selectSplitTypes,
  selectAdvisorBase,
  selectDashboardError,
  selectDashboardData,
  selectTotalSales,
  selectAdvisors,
  selectProductsTypes,
  selectCommissionTypes,
  selectMargin,
};

export const {
  setPendingEarlyPayments,
  setApprovedEarlyPayments,
  setPendingRefundPayments,
  setApprovedRefundPayments,
  setCompanyCommission,
  setTopPlans,
  setTopAdvisors,
  setTotalAdvisors,
  setProductTypes,
  setSplitTypes,
  setAdvisorBase,
  setTotalSales,
  setAdvisors,
  setProductsTypes,
  setError,
  setCommissionTypes,
  setMargin,
} = dashboardSlice.actions;

const actions = {
  ...dashboardSlice.actions,
};

export const dashboardActions = { actions, selectors };

export default dashboardSlice;
