import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REQUEST_REFUND_PAYMENT } from '_redux/types/payout.types';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';

const validationSchema = Yup.object({
  requestPaymentAmount: Yup.number()
    .required('Request refund amount is required')
    .positive('Must be a positive number'),
  reason: Yup.string()
    .required('Reason is required')
    .min(5, 'Reason must be at least 5 characters long'),
  requestDate: Yup.date().required('Request date is required'),
});

const UserRefundRequest = ({ onClose, open, payouts }) => {
  const dispatch = useDispatch();
  const [selectedPayout, setSelectedPayout] = useState(null);

  const handlePayoutChange = e => {
    const payout = payouts.find(p => p.advisorId === e.target.value);
    setSelectedPayout(payout);
  };

  const initialValues = {
    employeeId: selectedPayout ? selectedPayout.advisorId : '',
    employeeName: selectedPayout ? selectedPayout.advisorName : '',
    totalCommission: selectedPayout ? selectedPayout.totalAdvisorSplit : '',
    requestPaymentAmount: '',
    reason: '',
    requestDate: new Date().toISOString().substring(0, 10),
  };

  const handleSubmit = (values, { resetForm }) => {
    const requestData = {
      ...values,
    };

    dispatch({
      type: REQUEST_REFUND_PAYMENT,
      payload: requestData,
      onSuccess: () => {
        onClose();
        resetForm();
      },
    });
  };

  return (
    <ModalContainer open={open}>
      <Box p={3}>
        <Typography variant="h6" mb={3}>
          Refund Request
        </Typography>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form>
              {/* Employee (Advisor) selection field */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Select Advisor"
                    variant="outlined"
                    value={values.employeeId}
                    onChange={e => {
                      handlePayoutChange(e);
                      setFieldValue('employeeId', e.target.value);
                      setFieldValue(
                        'employeeName',
                        selectedPayout ? selectedPayout.advisorName : ''
                      );
                      setFieldValue(
                        'totalCommission',
                        selectedPayout ? selectedPayout.totalAdvisorSplit : ''
                      );
                    }}
                    error={touched.employeeId && Boolean(errors.employeeId)}
                    helperText={touched.employeeId && errors.employeeId}
                  >
                    {payouts.map(payout => (
                      <MenuItem key={payout.advisorId} value={payout.advisorId}>
                        {payout.advisorName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Populated fields based on selected employee */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="employeeId"
                    label="Advisor ID"
                    variant="outlined"
                    value={values.employeeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.employeeId && Boolean(errors.employeeId)}
                    helperText={touched.employeeId && errors.employeeId}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="employeeName"
                    label="Advisor Name"
                    variant="outlined"
                    value={values.employeeName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.employeeName && Boolean(errors.employeeName)}
                    helperText={touched.employeeName && errors.employeeName}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="totalCommission"
                    label="Total Commission Earned"
                    variant="outlined"
                    value={values.totalCommission}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.totalCommission && Boolean(errors.totalCommission)
                    }
                    helperText={
                      touched.totalCommission && errors.totalCommission
                    }
                    disabled
                  />
                </Grid>

                {/* Input fields */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="requestPaymentAmount"
                    label="Requested Refund Amount"
                    variant="outlined"
                    value={values.requestPaymentAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.requestPaymentAmount &&
                      Boolean(errors.requestPaymentAmount)
                    }
                    helperText={
                      touched.requestPaymentAmount &&
                      errors.requestPaymentAmount
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="reason"
                    label="Reason for Refund"
                    variant="outlined"
                    value={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.reason && Boolean(errors.reason)}
                    helperText={touched.reason && errors.reason}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="requestDate"
                    label="Request Date"
                    type="date"
                    variant="outlined"
                    value={values.requestDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.requestDate && Boolean(errors.requestDate)}
                    helperText={touched.requestDate && errors.requestDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={3} display="flex" justifyContent="end" gap={1}>
                <Button
                  variant="outlined"
                  onClick={onClose}
                  sx={{
                    color: '#003466',
                    borderColor: '#003466',
                    '&:hover': {
                      color: 'red',
                      borderColor: 'darkred',
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: '#003466',
                    '&:hover': {
                      borderColor: 'darkred',
                      backgroundColor: '#003466',
                    },
                  }}
                >
                  Submit Request
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalContainer>
  );
};

export default UserRefundRequest;
