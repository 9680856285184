import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  earlyPaymentsRequests: [],
  selectedEarlyPayment: null,
  total: 0,
};

const name = 'earlyPayments';
const parentSelector = state => state?.[name];

const earlyPaymentSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setEarlyPayments: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSelectedEarlyPayment: (state, action) => {
      return {
        ...state,
        selectedEarlyPayment: action.payload.selectedEarlyPayment,
      };
    },
  },
});

const getEarlyPayments = createSelector(
  parentSelector,
  state => state?.earlyPaymentsRequests
);
const getSelectedEarlyPayments = createSelector(
  parentSelector,
  state => state?.selectedEarlyPayment
);
const getTotalEarlyPayments = createSelector(
  parentSelector,
  state => state?.total
);

const actions = {
  ...earlyPaymentSlice.actions,
};

const selectors = {
  getEarlyPayments,
  getSelectedEarlyPayments,
  getTotalEarlyPayments,
};

export const earlyPaymentActions = { actions, selectors };

export default earlyPaymentSlice;
