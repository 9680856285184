import { call, put, all, takeEvery } from 'redux-saga/effects';

import {
  fetchPendingEarlyPaymentsService,
  fetchApprovedEarlyPaymentsService,
  fetchPendingRefundPaymentsService,
  fetchApprovedRefundPaymentsService,
  fetchCompanyCommissionService,
  fetchTopPlansService,
  fetchTopAdvisorsService,
  fetchTotalAdvisorsService,
  fetchProductTypesService,
  fetchSplitTypesService,
  fetchAdvisorBaseService,
  fetchTotalSalesService,
} from '_redux/services/dashboard.service';

import {
  setPendingEarlyPayments,
  setApprovedEarlyPayments,
  setPendingRefundPayments,
  setApprovedRefundPayments,
  setCompanyCommission,
  setTopPlans,
  setTopAdvisors,
  setTotalAdvisors,
  setProductTypes,
  setSplitTypes,
  setAdvisorBase,
  setTotalSales,
  setError,
} from '_redux/slices/dashboard.slice';
import { FETCH_DASHBOARD_DATA } from '_redux/types/dashboard.types';
import { FETCH_SALES_CHART } from '_redux/types/dashboard.types';
import { FETCH_MARGIN_CHART } from '_redux/types/dashboard.types';
import { fetchAdvisorsService } from '_redux/services/dashboard.service';
import { fetchProductsTypesService } from '_redux/services/dashboard.service';
import { setAdvisors } from '_redux/slices/dashboard.slice';
import { setProductsTypes } from '_redux/slices/dashboard.slice';
import { fetchCommissionTypesService } from '_redux/services/dashboard.service';
import { setCommissionTypes } from '_redux/slices/dashboard.slice';
import { exportMarginService } from '_redux/services/dashboard.service';
import { EXPORT_MARGIN } from '_redux/types/dashboard.types';
import { setMargin } from '_redux/slices/dashboard.slice';

function* fetchDashboardData() {
  try {
    const [
      pendingEarlyPayments,
      approvedEarlyPayments,
      pendingRefundPayments,
      approvedRefundPayments,
      companyCommission,
      topPlans,
      topAdvisors,
      totalAdvisors,
      productTypes,
      splitTypes,
      advisorBase,
      totalSales,
      advisors,
      productsTypes,
      commissionTypes,
    ] = yield all([
      call(fetchPendingEarlyPaymentsService),
      call(fetchApprovedEarlyPaymentsService),
      call(fetchPendingRefundPaymentsService),
      call(fetchApprovedRefundPaymentsService),
      call(fetchCompanyCommissionService),
      call(fetchTopPlansService),
      call(fetchTopAdvisorsService),
      call(fetchTotalAdvisorsService),
      call(fetchProductTypesService),
      call(fetchSplitTypesService),
      call(fetchAdvisorBaseService),
      call(fetchTotalSalesService, { period: 'week' }),
      call(fetchAdvisorsService),
      call(fetchProductsTypesService),
      call(fetchCommissionTypesService),
    ]);

    yield put(setPendingEarlyPayments(pendingEarlyPayments.data));
    yield put(setApprovedEarlyPayments(approvedEarlyPayments.data));
    yield put(setPendingRefundPayments(pendingRefundPayments.data));
    yield put(setApprovedRefundPayments(approvedRefundPayments.data));
    yield put(setCompanyCommission(companyCommission.data));
    yield put(setTopPlans(topPlans.data));
    yield put(setTopAdvisors(topAdvisors.data));
    yield put(setTotalAdvisors(totalAdvisors.data));
    yield put(setProductTypes(productTypes.data));
    yield put(setSplitTypes(splitTypes.data));
    yield put(setAdvisorBase(advisorBase.data));
    yield put(setTotalSales(totalSales.data));
    yield put(setAdvisors(advisors.data));
    yield put(setProductsTypes(productsTypes.data));
    yield put(setCommissionTypes(commissionTypes.data));
  } catch (error) {
    yield put(setError(error.message || 'Error fetching dashboard data'));
  }
}

function* fetchSalesChartData(action) {
  try {
    const response = yield call(fetchTotalSalesService, action.payload);
    yield put(setTotalSales(response.data));
  } catch (error) {
    yield put(setError(error.message || 'Error fetching sales chart data'));
  }
}

function* fetchMarginsChartData(action) {
  try {
    const response = yield call(fetchAdvisorBaseService, action.payload);
    yield put(setAdvisorBase(response.data));
  } catch (error) {
    yield put(setError(error.message || 'Error fetching sales chart data'));
  }
}

function* exportMarginChart(action) {
  try {
    const response = yield call(exportMarginService, action.payload);
    yield put(setMargin(response.data?.advisorBase));
  } catch (error) {
    yield put(setError(error.message || 'Error fetching sales chart data'));
  }
}

export function* watchDashboardAsync() {
  yield takeEvery(FETCH_DASHBOARD_DATA, fetchDashboardData);
  yield takeEvery(FETCH_SALES_CHART, fetchSalesChartData);
  yield takeEvery(FETCH_MARGIN_CHART, fetchMarginsChartData);
  yield takeEvery(EXPORT_MARGIN, exportMarginChart);
}
