import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from './newPassword.module.scss';
import { useDispatch } from 'react-redux';
import { CHANGE_PASSWORD } from '_redux/types/auth.types';
import toast from 'react-hot-toast';

const NewPassword = ({ email, onClose }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();

  const handlePasswordSubmit = e => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    dispatch({
      type: CHANGE_PASSWORD,
      payload: {
        email,
        newPassword,
        onSuccess: () => {
          onClose();
        },
      },
    });
  };

  return (
    <Box className={styles.formContainer}>
      <IconButton className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <LockIcon className={styles.lockIcon} />
      <h2>Create New Password</h2>
      <form className={styles.form} onSubmit={handlePasswordSubmit}>
        <TextField
          label="New Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <List className={styles.passwordCriteria}>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon className={styles.checkIcon} />
            </ListItemIcon>
            <Typography>Must be at least 8 characters</Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon className={styles.checkIcon} />
            </ListItemIcon>
            <Typography>Must contain one special character</Typography>
          </ListItem>
        </List>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#003466',
            '&:hover': {
              borderColor: 'darkred',
              backgroundColor: '#003466',
            },
          }}
        >
          Confirm Password
        </Button>
      </form>
    </Box>
  );
};

export default NewPassword;
