import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { payoutColumns } from 'utils/payout';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_PAYOUTS } from '_redux/types/payout.types';
import { payoutActions } from '_redux/slices/payout.slice';
import UserPayRequest from 'components/UserPayRequest/UserPayRequest';
import UserRefundRequest from 'components/UserRefundRequest/UserRefundRequest';
import { UPDATE_PAYOUT } from '_redux/types/payout.types';
import Expenses from 'components/Expenses/Expenses';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import AdvisorInfo from 'components/AdvisorInfo/AdvisorInfo.js';
import dayjs from 'dayjs';

const Payout = ({ handleTabChange, tab }) => {
  const dispatch = useDispatch();
  const payouts = useSelector(payoutActions.selectors.getPayouts);
  const totalPayouts = useSelector(payoutActions.selectors.getTotalPayouts);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [period, setPeriod] = useState('monthly');
  const [selectedMonth, setSelectedMonth] = useState(dayjs());

  const [isModalOpen, setModalOpen] = useState(false);
  const [isPayModalOpen, setPayModalOpen] = useState(false);
  const [isRefundModalOpen, setRefundModalOpen] = useState(false);
  const [isExpensesModalOpen, setExpensesModalOpen] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);

  useEffect(() => {
    const month = selectedMonth.month() + 1;
    const year = selectedMonth.year();
    dispatch({
      type: FETCH_PAYOUTS,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        period: period,
        month,
        year,
      },
    });
  }, [dispatch, page, rowsPerPage, selectedMonth]);

  const handleEarlyPaymentClick = () => {
    setPayModalOpen(true);
  };

  const handleRefundRequestClick = () => {
    setRefundModalOpen(true);
  };

  const handleModalClose = () => {
    setPayModalOpen(false);
    setRefundModalOpen(false);
    setExpensesModalOpen(false);
    setSelectedRows([]);
  };

  const handleExpensesModalOpen = rows => {
    setExpensesModalOpen(true);
    setSelectedRows(rows);
  };

  const handleUpdateRow = async updatedData => {
    try {
      dispatch({
        type: UPDATE_PAYOUT,
        payload: updatedData,
      });
    } catch (error) {
      console.error('Error updating data', error);
    }
  };

  const payout = payouts?.map((payout, index) => ({
    ...payout,
    id: index + 1,
  }));

  const handleViewPayout = advisorData => {
    setSelectedAdvisor(advisorData);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <GenericTable
        title="Advisors’s Payout"
        data={payout || []}
        columns={payoutColumns(handleViewPayout)}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalPayouts,
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
        isRefundRequest={true}
        isPayRequest={true}
        onEarlyPayRequest={handleEarlyPaymentClick}
        onRefundRequest={handleRefundRequestClick}
        onUpdateRows={handleUpdateRow}
        period={period}
        setPeriod={setPeriod}
        isPeriod={false}
        fetchType="payouts"
        onAddExpense={handleExpensesModalOpen}
        isAddExpense={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isDate={true}
        date={setSelectedMonth}
      />
      {isPayModalOpen && (
        <UserPayRequest
          onClose={handleModalClose}
          open={isPayModalOpen}
          payouts={payout}
        />
      )}
      {isRefundModalOpen && (
        <UserRefundRequest
          onClose={handleModalClose}
          open={isRefundModalOpen}
          payouts={payout}
        />
      )}
      {isExpensesModalOpen && (
        <Expenses
          onClose={handleModalClose}
          open={isExpensesModalOpen}
          selectedRows={selectedRows}
          limit={rowsPerPage}
          skip={page * rowsPerPage}
          period={period}
          month={selectedMonth.month() + 1}
          year={selectedMonth.year()}
        />
      )}
      <ModalContainer open={isModalOpen} onClose={handleCloseModal}>
        {selectedAdvisor && (
          <AdvisorInfo
            advisorData={selectedAdvisor}
            handleClose={handleCloseModal}
            limit={rowsPerPage}
            skip={page * rowsPerPage}
            period={period}
            month={selectedMonth.month() + 1}
            year={selectedMonth.year()}
          />
        )}
      </ModalContainer>
    </>
  );
};

export default Payout;
