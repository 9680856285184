import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  errors: [],
  validations: [],
  selectedErrorLog: null,
  totalErrorLogs: 0,
  totalvalidationLogs: 0,
};

const name = 'errorLogs';
const parentSelector = state => state?.[name];

const errorLogsSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setErrorLogs: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setValidations: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSelectedErrorLog: (state, action) => {
      return {
        ...state,
        selectedErrorLog: action.payload.selectedErrorLog,
      };
    },
  },
});

const getErrorLogs = createSelector(parentSelector, state => state?.errors);
const getValidations = createSelector(
  parentSelector,
  state => state?.validations
);

const getSelectedErrorLog = createSelector(
  parentSelector,
  state => state?.selectedErrorLog
);

const getTotalErrorLogs = createSelector(
  parentSelector,
  state => state?.totalErrorLogs
);

const getTotalValidations = createSelector(
  parentSelector,
  state => state?.totalvalidationLogs
);

const actions = {
  ...errorLogsSlice.actions,
};

const selectors = {
  getErrorLogs,
  getSelectedErrorLog,
  getTotalErrorLogs,
  getValidations,
  getTotalValidations,
};

export const errorLogsActions = { actions, selectors };

export default errorLogsSlice;
