import {
  DasboardIcon,
  DataUploadIcon,
  SupportIcon,
  MailIcon,
  ErrorIcon,
  SplitsIcon,
  EpmPayoutIcon,
  ReportIcon,
  SettingsIcon,
  RefundIcon,
  MarginIcon,
  UserManagement,
} from 'assets/svgs';

const ROUTES = [
  {
    path: '/',
    name: 'Dashboard',
    icon: <DasboardIcon />,
    roles: ['manager'],
    key: 'dashboard',
  },
  {
    path: '/margin',
    name: 'Margin',
    icon: <MarginIcon />,
    roles: ['manager'],
    key: 'margin',
  },
  {
    path: '/upload',
    name: 'Data Upload',
    icon: <DataUploadIcon />,
    roles: ['manager'],
    key: 'dataUpload',
  },
  {
    path: '/error-logs',
    name: 'Error Log',
    icon: <ErrorIcon />,
    roles: ['manager'],
    key: 'errorLog',
  },
  {
    path: '/commission-splits',
    name: 'Commission Splits',
    icon: <SplitsIcon />,
    roles: ['manager'],
    key: 'commissionSplits',
  },
  {
    path: '/advisors-payout',
    name: "Advisor's Payout",
    icon: <EpmPayoutIcon />,
    roles: ['manager'],
    key: 'advisorPayout',
  },
  {
    path: '/advisor-report',
    name: "Advisor's Report",
    icon: <ReportIcon />,
    roles: ['manager'],
    key: 'advisorReport',
  },
  {
    path: '/mail-box',
    name: 'Mail Box',
    icon: <MailIcon />,
    roles: ['manager'],
    key: 'mailBox',
  },
  {
    path: '/profile-settings',
    name: 'Profile Settings',
    icon: <SettingsIcon />,
    roles: ['manager'],
  },
  {
    path: '/support',
    name: 'Support',
    icon: <SupportIcon />,
    roles: ['manager'],
    key: 'support',
  },
  {
    path: '/payment-request',
    name: 'Payment Request',
    icon: <ReportIcon />,
    roles: ['admin'],
  },
  {
    path: '/refund-request',
    name: 'Refund Request',
    icon: <RefundIcon />,
    roles: ['admin'],
  },
  {
    path: '/mail-history',
    name: 'Mail Box',
    icon: <MailIcon />,
    roles: ['admin'],
  },
  {
    path: '/users-management',
    name: 'Users Management',
    icon: <UserManagement />,
    roles: ['admin'],
  },
];

export default ROUTES;
