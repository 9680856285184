import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { GET_ALL_MAILS } from '_redux/types/mail.types';
import { mailsActions } from '_redux/slices/mail.slice';
import { getMailsService } from '_redux/services/mail.service';
import { SEND_MAIL } from '_redux/types/mail.types';
import { sendMailService } from '_redux/services/mail.service';
import { GET_EMAIL_BY_ID } from '_redux/types/mail.types';
import { getMailByIdService } from '_redux/services/mail.service';

export function* fetchMailsSaga(action) {
  try {
    const response = yield call(getMailsService, action.payload);
    if (response?.status === 200) {
      yield put(mailsActions.actions.setMails(response.data));
    }
  } catch (error) {
    const errorMessage = error?.message || 'Failed to fetch mails';
    toast.error(errorMessage);
  }
}

export function* sendMailSaga(action) {
  try {
    const response = yield call(sendMailService, action.payload);
    if (response?.status === 201) {
      toast.success('Mail sent');
      yield put({ type: GET_ALL_MAILS });
    }
  } catch (error) {
    const errorMessage = error?.message || 'Failed to send mail';
    toast.error(errorMessage);
  }
}

export function* fetchMailByIdSaga(action) {
  try {
    const response = yield call(getMailByIdService, action.payload);
    if (response?.status === 200) {
      yield put(mailsActions.actions.setMail(response.data));
    }
  } catch (error) {
    const errorMessage = error?.message || 'Failed to fetch mail';
    toast.error(errorMessage);
  }
}

export function* watchMailsAsync() {
  yield takeEvery(GET_ALL_MAILS, fetchMailsSaga);
  yield takeEvery(SEND_MAIL, sendMailSaga);
  yield takeEvery(GET_EMAIL_BY_ID, fetchMailByIdSaga);
}
