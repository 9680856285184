import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Box, Breadcrumbs, Typography } from '@mui/material';
import styles from './upload.module.scss';
import { UploadData } from 'components/UploadData';
import { UploadFile } from 'components/UploadFile';
import { GenericTable } from 'components/common/GenericTable';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_FILE_DATA } from '_redux/types/files.types';
import { SALES_COLUMNS } from 'utils/salesData';
import { filesActions } from '_redux/slices/files.slice';
import { Link, useParams } from 'react-router-dom';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { UPDATE_FILE } from '_redux/types/files.types';

const Upload = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [step, setStep] = useState('uploadData');
  const [isModalOpen, setModalOpen] = useState(false);
  const fileData = useSelector(filesActions.selectors.getFileData);
  const [edit, setEdit] = useState(false);

  const handleUploadClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleFileUploadComplete = useCallback(() => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    if (id) {
      setStep('salesData');
      dispatch({ type: FETCH_FILE_DATA, payload: id });
    }
  }, [id, dispatch]);

  const handleUpdateRow = async updatedData => {
    try {
      dispatch({
        type: UPDATE_FILE,
        payload: updatedData,
        id: id,
      });
      setEdit(false);
    } catch (error) {
      console.error('Error updating data', error);
    }
  };

  const handleEditBtnClick = () => {
    setEdit(true);
  };

  const handleCancelClick = () => {
    setEdit(false);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = fileData?.SalesData?.slice(startIndex, endIndex) || [];

  const renderContent = () => {
    switch (step) {
      case 'uploadData':
        return <UploadData onUploadClick={handleUploadClick} />;
      case 'uploadFile':
        return (
          <UploadFile
            onUploadComplete={handleFileUploadComplete}
            limit={rowsPerPage}
            skip={page * rowsPerPage}
          />
        );
      case 'salesData':
        return (
          <GenericTable
            title="Sales Data"
            data={paginatedData}
            columns={SALES_COLUMNS}
            pageConfig={{
              page,
              setPage,
              rowsPerPage,
              setRowsPerPage,
              totalRows: fileData?.SalesData?.length || 0,
            }}
            isDashboard={true}
            isEdit={edit}
            isDownload={false}
            onUpdateRows={handleUpdateRow}
            handleEditClick={handleEditBtnClick}
            onCancelClick={handleCancelClick}
            isEditable={true}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.dashboardContainer}>
      {step !== 'uploadData' ? (
        <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
          <Link
            to="/upload"
            underline="hover"
            color="#666666"
            onClick={() => setStep('uploadData')}
          >
            Upload Data
          </Link>
          {step === 'salesData' && (
            <Typography color="textPrimary">Sales Data</Typography>
          )}
        </Breadcrumbs>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderContent()}
        </Grid>
      </Grid>
      <ModalContainer open={isModalOpen}>
        <UploadFile
          onUploadComplete={handleFileUploadComplete}
          limit={rowsPerPage}
          skip={page * rowsPerPage}
        />
      </ModalContainer>
    </Box>
  );
};

export default Upload;
