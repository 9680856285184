import React, { useEffect, useState } from 'react';
import {
  Popover,
  Box,
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
} from '@mui/material';
import styles from './notificationPopover.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '_redux/slices/profile.slice';
import { getTime } from 'utils';
import { formatDate } from 'utils';
import { NotificationIcon } from 'assets/svgs';
import { isNewNotification } from 'utils';
import { getUnseenNotifications } from 'utils';
import { VIEW_NOTIFICATIONS } from '_redux/types/profile.types';

const NotificationsPopover = () => {
  const notifications = useSelector(profileActions.selectors.getNotifications);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  useEffect(() => {
    dispatch({
      type: VIEW_NOTIFICATIONS,
      payload: getUnseenNotifications(notifications),
    });
  }, [dispatch, id]);

  return (
    <>
      <IconButton className={styles.iconButton} onClick={handleClick}>
        <NotificationIcon isNew={isNewNotification(notifications)} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={styles.popover}
      >
        <Box p={2}>
          <Typography variant="h6" className={styles.popoverTitle}>
            Notifications
          </Typography>
          <List className={styles.notificationList}>
            {notifications?.map((notification, index) => (
              <ListItem
                key={index}
                className={styles.notificationItem}
                style={{
                  background: index % 2 == 0 ? '#F4F7FCBF' : '#F1F1F1BF',
                }}
              >
                <Avatar
                  src={notification.avatar}
                  alt="User Avatar"
                  className={styles.avatar}
                />
                <ListItemText
                  primary={
                    <Typography className={styles.notificationText}>
                      {notification?.note}
                    </Typography>
                  }
                  secondary={
                    <Typography className={styles.notificationTime}>
                      {`${formatDate(notification?.date)}, ${getTime(notification?.date)}`}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
            {notifications?.length <= 0 ? (
              <ListItem className={styles.notificationItem}>
                <ListItemText
                  primary={
                    <Typography className={styles.notificationText}>
                      {'Not Found'}
                    </Typography>
                  }
                />
              </ListItem>
            ) : null}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
