import React from 'react';

const Type = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1195_27179)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.1611 14.6504C17.5046 14.6504 13.7236 18.4313 13.7236 23.0879C13.7236 27.7445 17.5046 31.5254 22.1611 31.5254C26.8177 31.5254 30.5986 27.7445 30.5986 23.0879C30.5986 18.4313 26.8177 14.6504 22.1611 14.6504ZM22.1611 16.5254C25.7827 16.5254 28.7236 19.4663 28.7236 23.0879C28.7236 26.7095 25.7827 29.6504 22.1611 29.6504C18.5396 29.6504 15.5986 26.7095 15.5986 23.0879C15.5986 19.4663 18.5396 16.5254 22.1611 16.5254ZM21.2236 19.3623C20.1868 19.5235 19.3486 20.4779 19.3486 21.6816C19.3486 23.0045 20.3602 24.0254 21.5358 24.0254H22.7864C22.9927 24.0254 23.0986 24.2616 23.0986 24.4941C23.0986 24.7266 22.9927 24.9629 22.7864 24.9629H20.2861C19.7686 24.9629 19.3486 25.3829 19.3486 25.9004C19.3486 26.4179 19.7686 26.8379 20.2861 26.8379H21.2236V27.7754C21.2236 28.2929 21.6436 28.7129 22.1611 28.7129C22.6786 28.7129 23.0986 28.2929 23.0986 27.7754V26.8135C24.1355 26.6523 24.9736 25.6979 24.9736 24.4941C24.9736 23.1713 23.9621 22.1504 22.7864 22.1504H21.5358C21.3296 22.1504 21.2236 21.9141 21.2236 21.6816C21.2236 21.4491 21.3296 21.2129 21.5358 21.2129H24.0361C24.5536 21.2129 24.9736 20.7929 24.9736 20.2754C24.9736 19.7579 24.5536 19.3379 24.0361 19.3379H23.0986V18.4004C23.0986 17.8829 22.6786 17.4629 22.1611 17.4629C21.6436 17.4629 21.2236 17.8829 21.2236 18.4004V19.3623ZM4.34863 11.8379H24.9736V13.7129C24.9736 14.2304 25.3936 14.6504 25.9111 14.6504C26.4286 14.6504 26.8486 14.2304 26.8486 13.7129V8.08789C26.8486 6.53539 25.5886 5.27539 24.0361 5.27539H5.28613C3.73363 5.27539 2.47363 6.53539 2.47363 8.08789V19.3379C2.47363 20.8904 3.73363 22.1504 5.28613 22.1504H11.8486C12.3661 22.1504 12.7861 21.7304 12.7861 21.2129C12.7861 20.6954 12.3661 20.2754 11.8486 20.2754H5.28613C4.76863 20.2754 4.34863 19.8554 4.34863 19.3379V11.8379ZM24.9736 9.96289V8.08789C24.9736 7.57039 24.5536 7.15039 24.0361 7.15039H5.28613C4.76863 7.15039 4.34863 7.57039 4.34863 8.08789V9.96289H24.9736Z"
          fill="#FFCC00"
        />
      </g>
      <defs>
        <clipPath id="clip0_1195_27179">
          <rect width="32.8417" height="32.8417" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Type;
