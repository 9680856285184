import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { COMMISSION_COLUMNS } from 'utils/commission';
import { commissionSplitActions } from '_redux/slices/commissionSplit.slice';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_COMMISSION_SPLITS } from '_redux/types/commissionSplit.types';
import { UPDATE_COMMISSION_SPLIT } from '_redux/types/commissionSplit.types';
import { filesActions } from '_redux/slices/files.slice';

const Split = ({ handleTabChange, tab, tabs }) => {
  const dispatch = useDispatch();
  const commissionSplits = useSelector(
    commissionSplitActions.selectors.getCommissionSplits
  );
  const totalCommissionSplits = useSelector(
    commissionSplitActions.selectors.getTotalCommissionSplits
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [edit, setEdit] = useState(false);

  const files = useSelector(filesActions.selectors.getFiles);
  const selectedFile = useSelector(filesActions.selectors.getSelectedSplitFile);
  const [selectedFileId, setSelectedFileId] = useState(
    selectedFile || files[0]?.id
  );

  useEffect(() => {
    dispatch({
      type: FETCH_COMMISSION_SPLITS,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        id: selectedFileId,
      },
    });
  }, [dispatch, page, rowsPerPage, selectedFileId]);

  const handleUpdateRow = async updatedData => {
    try {
      dispatch({
        type: UPDATE_COMMISSION_SPLIT,
        payload: updatedData,
        pagination: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
          id: selectedFileId,
        },
      });
      setEdit(false);
    } catch (error) {
      console.error('Error updating data', error);
    }
  };

  const handleEditBtnClick = () => {
    setEdit(true);
  };

  const handleCancelClick = () => {
    setEdit(false);
  };

  const handleSelectedFileChange = fileId => {
    setSelectedFileId(fileId);
    dispatch(filesActions.actions.setSelectedSplitFile(fileId));
  };

  return (
    <GenericTable
      title="Commission Split"
      data={commissionSplits}
      columns={COMMISSION_COLUMNS}
      pageConfig={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalRows: totalCommissionSplits,
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
      isEdit={edit}
      onUpdateRows={handleUpdateRow}
      fetchType="commissions"
      handleEditClick={handleEditBtnClick}
      onCancelClick={handleCancelClick}
      isEditable={true}
      isFile={true}
      selectedFileId={selectedFileId}
      setSelectedFileId={handleSelectedFileChange}
    />
  );
};

export default Split;
