import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { ERRORS_COLUMNS } from 'utils/errorsData';
import { useDispatch, useSelector } from 'react-redux';
import { errorLogsActions } from '_redux/slices/errorLogs.slice';
import { FETCH_ERROR_LOGS } from '_redux/types/errorLogs.types';
import { filesActions } from '_redux/slices/files.slice';
import { GET_FILES } from '_redux/types/files.types';

const Error = ({ handleTabChange, tab, tabs }) => {
  const dispatch = useDispatch();
  const errorLogs = useSelector(errorLogsActions.selectors.getErrorLogs);
  const totalErrorLogs = useSelector(
    errorLogsActions.selectors.getTotalErrorLogs
  );
  useEffect(() => {
    dispatch({
      type: GET_FILES,
      payload: {
        limit: 100,
        skip: 0,
      },
    });
  }, [dispatch]);
  const files = useSelector(filesActions.selectors.getFiles);
  const selectedFile = useSelector(filesActions.selectors.getSelectedFile);
  const [selectedFileId, setSelectedFileId] = useState(
    selectedFile || files[0]?.id
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch({
      type: FETCH_ERROR_LOGS,
      payload: { limit: rowsPerPage, skip: page * rowsPerPage, selectedFileId },
    });
  }, [dispatch, page, rowsPerPage, selectedFileId]);

  const handleSelectedFileChange = fileId => {
    setSelectedFileId(fileId);
    dispatch(filesActions.actions.setSelectedFile(fileId));
  };

  return (
    <GenericTable
      title="Error Logs"
      data={errorLogs || []}
      columns={ERRORS_COLUMNS}
      pageConfig={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalRows: totalErrorLogs,
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
      fetchType="errorLogs"
      isFile={true}
      selectedFileId={selectedFileId}
      setSelectedFileId={handleSelectedFileChange}
    />
  );
};

export default Error;
