import apiInterceptor from './Interceptors/apiInterceptor';

export const getPayoutsService = async ({
  limit,
  skip,
  period,
  year,
  month,
}) => {
  try {
    const response = await apiInterceptor.get(
      `/api/payouts?limit=${limit}&skip=${skip}&month=${month - 1}&year=${year}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const getPayoutsService1 = async ({ limit, skip, month, year }) => {
  try {
    const response = await apiInterceptor.get(
      `/api/payouts?limit=${limit}&skip=${skip}&month=${month - 1}&year=${year}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const earlyPaymentService = async requestData => {
  return await apiInterceptor.post('/api/earlyPayment/request', requestData);
};

export const requestRefundPaymentService = async requestData => {
  return await apiInterceptor.post('/api/refundPayment/request', requestData);
};

export const updatePayoutService = async updatedData => {
  try {
    const response = await apiInterceptor.put(
      '/api/payouts/updatePayout?limit=10&skip=0',
      updatedData
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const addPayoutService = async updatedData => {
  try {
    const response = await apiInterceptor.put(
      '/api/payouts/appendExpenses?limit=10&skip=0',
      updatedData
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const replacePayoutService = async updatedData => {
  try {
    const response = await apiInterceptor.put(
      '/api/payouts/updateExpenses',
      updatedData
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
