import React, { useMemo, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import CsvIcon from 'assets/icons/csv.svg';
import { useDropzone } from 'react-dropzone';
import styles from './uploadFile.module.scss';
import { Upload } from 'assets/svgs';
import { Title } from 'components/common/Title';
import { useDispatch, useSelector } from 'react-redux';
import { UPLOAD_FILE, SET_UPLOAD_PROGRESS } from '_redux/types/files.types';
import { filesActions } from '_redux/slices/files.slice';
import { Close } from '@mui/icons-material';

const UploadFile = ({ onUploadComplete, limit, skip }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const uploadProgress = useSelector(filesActions.selectors.getUplaodProgress);
  const [category, setCategory] = useState('monthly');
  const dispatch = useDispatch();

  const onDrop = useCallback(
    acceptedFiles => {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      dispatch({ type: SET_UPLOAD_PROGRESS, payload: 0 });
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv, .xlsx',
    maxSize: 50 * 1024 * 1024, // 50MB limit
  });

  const handleUpload = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('category', category);

    setUploading(true);

    dispatch({
      type: UPLOAD_FILE,
      payload: {
        formData,
        onUploadComplete: () => {
          setUploading(false);
          onUploadComplete();
        },
        limit,
        skip,
      },
    });
  };

  const uploadedSize = useMemo(
    () => Math.round((uploadProgress / 100) * (file?.size || 0)),
    [uploadProgress, file]
  );

  const remainingSize = useMemo(
    () => (file ? file.size - uploadedSize : 0),
    [file, uploadedSize]
  );

  return (
    <Box className={styles.uploadContainer}>
      <IconButton className={styles.closeButton} onClick={onUploadComplete}>
        <Close />
      </IconButton>
      <Box sx={{ width: '40%' }}>
        <Title title={'Upload File'} />
      </Box>
      <br />
      <Typography variant="body2" className={styles.subtitle}>
        Upload and attach files to this project.
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={category === 'monthly'}
              onChange={() => setCategory('monthly')}
            />
          }
          label="Monthly Report"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={category === 'weekly'}
              onChange={() => setCategory('weekly')}
            />
          }
          label="Weekly Report"
        />
      </Box>
      <Box {...getRootProps()} className={styles.fileUploadBox}>
        <input {...getInputProps()} />
        <Upload />
        <Typography variant="body1" color="#000000">
          {isDragActive
            ? 'Drop the files here...'
            : 'Choose a file or drag & drop it here'}
        </Typography>
        <Typography variant="body2" color="#687182">
          Maximum file size 50MB
        </Typography>
      </Box>
      {file && (
        <Box className={styles.fileDetailsBox}>
          <Box className={styles.fileIcon}>
            <img src={CsvIcon} alt="CSV or XLSX Icon" />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="body1" className={styles.fileName}>
              {file.name}
            </Typography>
            <Typography variant="body2" className={styles.fileSize}>
              {`${Math.round(uploadedSize / 1024)} KB uploaded of ${Math.round(file.size / 1024)} KB`}
              • {`${Math.round(remainingSize / 1024)} KB remaining`}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              className={styles.progressBar}
            />
          </Box>
          <IconButton
            onClick={() => setFile(null)}
            className={styles.cancelButton}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )}
      <Button
        variant="contained"
        fullWidth
        startIcon={
          uploading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CloudUploadIcon />
          )
        }
        onClick={handleUpload}
        disabled={!file || uploading}
        className={styles.uploadButton}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>
    </Box>
  );
};

export default UploadFile;
