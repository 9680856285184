import React from 'react';
import styles from './greeting.module.scss';
import { authActions } from '_redux/slices/auth.slice';
import { useSelector } from 'react-redux';

const Greeting = ({ name }) => {
  const { user } = useSelector(authActions.selectors.getUser);

  return (
    <div className={styles.greeting}>
      Welcome Back, {user?.firstName || 'unknown name'}
    </div>
  );
};

export default Greeting;
