import { Visibility } from '@mui/icons-material';
import { Button } from '@mui/material';

export const PAYOUT_DATA = [
  {
    id: 1,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 2,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 3,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 4,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 5,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 6,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 7,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 8,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
];

export const payoutColumns = handleViewPayout => [
  { headerName: 'Advisors Name', field: 'advisorName' },
  { headerName: 'Period', field: 'period' },
  {
    headerName: 'Total Gross FCI',
    field: 'totalGrossFCI',
    renderCell: row => `£${row?.totalGrossFCI?.toFixed(2) || 0}`,
  },
  {
    headerName: 'Total Advisor Split',
    field: 'totalAdvisorSplit',
    renderCell: row => `£${row?.totalAdvisorSplit?.toFixed(2) || 0}`,
  },
  {
    headerName: 'Total Deduction',
    field: 'totalDeduction',
    // editAble: true,
    renderCell: row => `£${row?.totalDeduction?.toFixed(2) || 0}`,
  },
  {
    headerName: 'Net Payout',
    field: 'netPayout',
    renderCell: row => `£${row?.netPayout?.toFixed(2) || 0}`,
  },
  {
    headerName: 'Share Margin',
    field: 'lgShareMargin',
    renderCell: row => `£${row?.LgMargin?.toFixed(2) || 0}`,
  },
  {
    headerName: 'View',
    renderCell: row => (
      <Button
        variant="text"
        onClick={() => handleViewPayout(row)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Visibility sx={{ color: '#687182' }} />
      </Button>
    ),
  },
];
