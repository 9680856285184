import apiInterceptor from './Interceptors/apiInterceptor';

export const getEarlyPaymentsService = async payload => {
  try {
    const response = await apiInterceptor.get(
      `/api/earlyPayment?limit=${payload?.limit}&skip=${payload?.skip}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const getEarlyPaymentByIdService = async payload => {
  try {
    const response = await apiInterceptor.get(
      `/api/earlyPayment/${payload?.id}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const adminVerificationService = async payload => {
  try {
    const response = await apiInterceptor.post(
      `/api/earlyPayment/verifyByAdmin/${payload?.id}`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
