import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import styles from './verificationCode.module.scss';
import { useDispatch } from 'react-redux';
import { VERIFY_OTP, RESEND_OTP } from '_redux/types/auth.types';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const VerificationCode = ({
  email,
  onClose,
  onVerified,
  isPassword = false,
  isEmail = false,
}) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [timer, setTimer] = useState(120);
  const inputRefs = useRef([]);
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsTimerExpired(true);
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer]);

  const handleChange = (element, index) => {
    if (/^[0-9]$/.test(element.value)) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
    if (event.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    if (event.key === 'ArrowRight' && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const verifyOtp = () => {
    const otpCode = otp.join('');
    if (otpCode.length !== 6) {
      toast.error('Please enter a valid 6-digit OTP');
      return;
    }

    dispatch({
      type: VERIFY_OTP,
      payload: {
        email,
        otp: otpCode,
        onSuccess: () => {
          if (isPassword || isEmail) {
            onVerified();
          } else {
            navigate('/login');
          }
          onClose();
        },
      },
    });
  };

  const resendOtp = () => {
    dispatch({
      type: RESEND_OTP,
      payload: { email },
    });
    setTimer(120); // Reset the timer
    setIsTimerExpired(false);
  };

  return (
    <Box className={styles.container}>
      <IconButton className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <EmailIcon className={styles.emailIcon} />

      <Typography variant="h6" className={styles.title}>
        We’ve emailed you a code
      </Typography>
      <Typography className={styles.subtitle}>
        To continue account setup, enter the code we’ve sent you at {email}
      </Typography>

      <Box className={styles.codeInputContainer}>
        {otp.map((value, index) => (
          <TextField
            key={index}
            inputRef={el => (inputRefs.current[index] = el)}
            value={value}
            onChange={e => handleChange(e.target, index)}
            onKeyDown={e => handleKeyDown(e, index)}
            variant="outlined"
            className={styles.codeInput}
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center', fontSize: '24px' },
            }}
          />
        ))}
      </Box>

      <Typography className={styles.timer}>
        {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}{' '}
        minutes
      </Typography>

      <Button
        variant="contained"
        className={styles.verifyButton}
        onClick={verifyOtp}
        disabled={isTimerExpired}
      >
        VERIFY
      </Button>

      <Typography className={styles.resendText}>
        Didn’t get the code?{' '}
        <span className={styles.resendLink} onClick={resendOtp}>
          Resend it
        </span>
      </Typography>
    </Box>
  );
};

export default VerificationCode;
