import React from 'react';

const Notification = ({ isNew = false }) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8331 25.8305C11.9885 25.8305 9.14398 25.3788 6.44594 24.4754C5.42044 24.1213 4.6391 23.4011 4.29727 22.461C3.94323 21.521 4.06531 20.4833 4.6269 19.5554L6.03085 17.2236C6.32385 16.7353 6.59244 15.7586 6.59244 15.1848V11.6566C6.59244 7.11514 10.2916 3.41602 14.8331 3.41602C19.3746 3.41602 23.0737 7.11514 23.0737 11.6566V15.1848C23.0737 15.7464 23.3423 16.7353 23.6353 17.2358L25.027 19.5554C25.552 20.4344 25.6496 21.4966 25.2956 22.461C24.9416 23.4255 24.1724 24.158 23.208 24.4754C20.5221 25.3788 17.6776 25.8305 14.8331 25.8305ZM14.8331 5.24727C11.3049 5.24727 8.42369 8.11622 8.42369 11.6566V15.1848C8.42369 16.0761 8.05744 17.3946 7.60573 18.1637L6.20177 20.4955C5.93319 20.9472 5.85994 21.4233 6.01865 21.8262C6.16515 22.2413 6.5314 22.5587 7.03194 22.7296C12.135 24.4388 17.5433 24.4388 22.6464 22.7296C23.0859 22.5831 23.4277 22.2535 23.5864 21.814C23.7451 21.3745 23.7085 20.8983 23.4644 20.4955L22.0604 18.1637C21.5965 17.3701 21.2424 16.0638 21.2424 15.1726V11.6566C21.2424 8.11622 18.3735 5.24727 14.8331 5.24727Z"
        fill="#687182"
      />
      <path
        d="M17.1041 5.57653C17.0187 5.57653 16.9332 5.56432 16.8477 5.53991C16.4937 5.44224 16.1519 5.36899 15.8222 5.32016C14.7845 5.18586 13.7835 5.25911 12.8434 5.53991C12.5016 5.64978 12.1353 5.53991 11.9034 5.28353C11.6714 5.02716 11.5982 4.66091 11.7325 4.33128C12.233 3.04941 13.4538 2.20703 14.8456 2.20703C16.2373 2.20703 17.4582 3.0372 17.9587 4.33128C18.0808 4.66091 18.0197 5.02716 17.7878 5.28353C17.6047 5.47886 17.3483 5.57653 17.1041 5.57653Z"
        fill="#687182"
      />
      <path
        d="M14.8333 28.6143C13.6246 28.6143 12.4526 28.1259 11.598 27.2713C10.7435 26.4168 10.2551 25.2448 10.2551 24.0361H12.0864C12.0864 24.7564 12.3794 25.4645 12.8921 25.9773C13.4049 26.49 14.113 26.783 14.8333 26.783C16.3471 26.783 17.5801 25.55 17.5801 24.0361H19.4114C19.4114 26.5633 17.3604 28.6143 14.8333 28.6143Z"
        fill="#687182"
      />
      {isNew ? (
        <circle
          cx="20.913"
          cy="6.87051"
          r="3.05208"
          fill="#F46D22"
          stroke="white"
          stroke-width="1.22083"
        />
      ) : null}
    </svg>
  );
};

export default Notification;
