import React from 'react';

const Product = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.965 7.07025L14.965 0.07025C14.7773 -0.0234167 14.556 -0.0234167 14.3687 0.07025L0.368667 7.07025C0.142667 7.18325 0 7.41392 0 7.66658V21.6666C0 21.9193 0.142667 22.1499 0.368333 22.2629L14.3683 29.2629C14.4623 29.3099 14.5643 29.3333 14.6667 29.3333C14.769 29.3333 14.871 29.3099 14.965 29.2629L28.965 22.2629C29.1907 22.1499 29.3333 21.9193 29.3333 21.6666V7.66658C29.3333 7.41392 29.1907 7.18325 28.965 7.07025ZM14.6667 1.41192L27.176 7.66658L24.5 9.00458L11.991 2.74992L14.6667 1.41192ZM19.1667 11.6712L6.65733 5.41658L10.5 3.49525L23.0093 9.74992L19.1667 11.6712ZM19.6667 12.9119L23.6667 10.9119V15.8709L22.3873 14.9573C22.2423 14.8536 22.061 14.8126 21.8853 14.8429C21.7097 14.8739 21.5533 14.9736 21.4517 15.1202L19.6667 17.6992V12.9119ZM5.16667 6.16192L17.6757 12.4166L14.6667 13.9212L2.15733 7.66658L5.16667 6.16192ZM1.33333 8.74525L14 15.0786V27.5876L1.33333 21.2542V8.74525ZM15.3333 27.5879V15.0786L18.3333 13.5786V19.8333C18.3333 20.1249 18.5227 20.3826 18.801 20.4696C18.867 20.4902 18.9337 20.4999 19 20.4999C19.2147 20.4999 19.4213 20.3959 19.5483 20.2129L22.1633 16.4359L23.9463 17.7092C24.1497 17.8542 24.417 17.8739 24.6387 17.7593C24.8603 17.6453 25 17.4163 25 17.1666V10.2453L28 8.74525V21.2542L15.3333 27.5879Z"
        fill="#FF718B"
      />
    </svg>
  );
};

export default Product;
