import { changePermissionsService } from '_redux/services/userManagement.service';
import { getUsersService } from '_redux/services/userManagement.service';
import { userManagementActions } from '_redux/slices/userManagement.slice';
import { CHANGE_USER_PERMISSIONS } from '_redux/types/userManagement.types';
import { GET_USERS } from '_redux/types/userManagement.types';
import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';

export function* getUsersSaga(action) {
  try {
    const response = yield call(getUsersService, action.payload);

    if (response?.status === 200) {
      yield put(
        userManagementActions.actions.setUsers({
          users: response?.data?.users?.length ? response?.data?.users : [],
          total: response?.data?.total || 0,
        })
      );
    }
  } catch (err) {
    // const errorMessage = err?.response?.data?.message;
    console.log('error: ', err);
  }
}

export function* changePermissionsSaga(action) {
  const { id, checked, index, tabName } = action.payload;
  try {
    yield put(
      userManagementActions.actions.updateUserPermission({
        checked,
        index,
        tabName,
      })
    );
    const response = yield call(changePermissionsService, {
      id,
      permissions: { [tabName]: checked },
    });
    if (response?.status !== 200) {
      toast.error('Updation Failed!');
      yield put(
        userManagementActions.actions.updateUserPermission({
          checked: !checked,
          index,
          tabName,
        })
      );
    } else {
      toast.success('Permissions Updated!');
    }
  } catch (err) {
    // const errorMessage = err?.response?.data?.message;
    toast.error('Updation Failed!');
    yield put(
      userManagementActions.actions.updateUserPermission({
        checked: !checked,
        index,
        tabName,
      })
    );
    console.log('error: ', err);
  }
}

export function* watchUserManagementAsync() {
  yield takeEvery(GET_USERS, getUsersSaga);
  yield takeEvery(CHANGE_USER_PERMISSIONS, changePermissionsSaga);
}
