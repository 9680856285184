import React from 'react';
import styles from './statItem.module.scss';
import { motion } from 'framer-motion';
import { container } from 'utils/framer';
import { item } from 'utils/framer';

const StatItem = ({ icon, label, count, backgroundColor }) => {
  return (
    <motion.div
      className={styles.statItem}
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className={styles.iconContainer}
        style={{ backgroundColor }}
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 30,
        }}
      >
        {icon}
      </motion.div>
      <div className={styles.details}>
        <motion.span className={styles.label} variants={item}>
          {label}
        </motion.span>
        <motion.span className={styles.count} variants={item}>
          {count || 0}
        </motion.span>
      </div>
    </motion.div>
  );
};

export default StatItem;
