import React from 'react';
import styles from './iconButtons.module.scss';
import NotificationsPopover from '../NotificationPopover/NotificationPopover';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { authActions } from '_redux/slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { EmailIcon } from 'assets/svgs';

const IconButtons = () => {
  const navigate = useNavigate();
  const { user } = useSelector(authActions.selectors.getUser);

  const handleMailClick = () => {
    if (user?.role == 'admin') {
      navigate('/mail-history');
    } else {
      navigate('/mail-box');
    }
  };

  return (
    <div className={styles.iconContainer}>
      {user?.role != 'admin' ? <NotificationsPopover /> : null}
      <IconButton className={styles.iconButton} onClick={handleMailClick}>
        <EmailIcon />
      </IconButton>
    </div>
  );
};

export default IconButtons;
