export const marginColumns = [
  {
    field: 'advisorName',
    headerName: 'Advisor Name',
    editable: false,
  },
  { field: 'month', headerName: 'Month', editable: false },
  { field: 'productType', headerName: 'Product Type', editable: false },
  {
    field: 'commissionType',
    headerName: 'Monthly Commission Type',
    editable: false,
  },
  {
    field: 'totalFCIRecognition',
    headerName: 'Margin',
    editable: false,
  },
];
