import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  commissions: [],
  totalCommissions: 0,
  selectedCommission: null,
};

const name = 'commissionSplits';
const parentSelector = state => state?.[name];

const commissionSplitSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setCommissionSplits: (state, action) => {
      return {
        ...state,
        commissions: action.payload.commissions,
        totalCommissions: action.payload.totalCommissions,
      };
    },
    setSelectedCommissionSplit: (state, action) => {
      return {
        ...state,
        selectedCommission: action.payload.selectedCommission,
      };
    },
  },
});

const getCommissionSplits = createSelector(
  parentSelector,
  state => state?.commissions
);

const getTotalCommissionSplits = createSelector(
  parentSelector,
  state => state?.totalCommissions
);

const getSelectedCommissionSplit = createSelector(
  parentSelector,
  state => state?.selectedCommission
);

const actions = {
  ...commissionSplitSlice.actions,
};

const selectors = {
  getCommissionSplits,
  getTotalCommissionSplits,
  getSelectedCommissionSplit,
};

export const commissionSplitActions = { actions, selectors };

export default commissionSplitSlice;
