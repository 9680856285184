import apiInterceptor from './Interceptors/apiInterceptor';

export const getUsersService = async payload => {
  try {
    const response = await apiInterceptor.get(
      `/api/user/get-all-users?limit=${payload?.limit}&skip=${payload?.skip}&search=${payload?.search?.trim() || ''}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const changePermissionsService = async payload => {
  try {
    const response = await apiInterceptor.put(
      `/api/user/employee/${payload?.id}/permissions`,
      { permissions: payload?.permissions }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
