import React from 'react';
import Grid from '@mui/material/Grid';
import StatItem from './StatItem/StatItem';
import { MARGIN_STATS_DATA } from 'utils';
import styles from './stats.module.scss';

const MarginStats = ({
  commission = 0,
  totalAdvisors,
  productTypes,
  splitTypes,
}) => {
  return (
    <Grid container spacing={2}>
      {MARGIN_STATS_DATA.map((stat, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={index}
          className={styles.statsContainer}
        >
          <StatItem
            icon={stat.icon}
            label={stat.label}
            count={
              index === 0
                ? `£${Math.ceil(commission)}`
                : index === 1
                  ? totalAdvisors
                  : index === 2
                    ? productTypes
                    : index === 3
                      ? splitTypes
                      : stat.count
            }
            backgroundColor={stat.backgroundColor}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default MarginStats;
