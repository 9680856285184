import { Search } from '@mui/icons-material';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { ArrowDropDownIcon, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Title } from 'components/common/Title';
import Animation from 'utils/Animation';
import { card } from 'utils/styles';
import styles from './genericTable.module.scss';
import LeftToRight from 'utils/LeftToRight';

const UsersTable = ({
  title,
  data = [],
  columns,
  pageConfig,
  handleSearchChange,
  searchQuery,
}) => {
  const { page, setPage, rowsPerPage, setRowsPerPage, totalRows } = pageConfig;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card sx={card}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box className={styles.buttonsBox}>
            <Title title={title} />
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            p={1}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <Search />,
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E0E0E0',
                  },
                },
              }}
              sx={{ width: '100%' }}
            />
          </Box>
          <TableContainer className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      className={styles.tableHeadCell}
                      padding={column.padding || 'normal'}
                    >
                      <Animation delay={index * 0.1}>
                        {column.headerName}
                        {column.headerName === 'Split Type' && (
                          <IconButton size="small" style={{ marginLeft: 4 }}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        )}
                      </Animation>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      backgroundColor: index % 2 == 0 ? '#ffffff' : '#F9FAFC',
                    }}
                  >
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        className={styles.tableBodyCell}
                      >
                        <LeftToRight index={index}>
                          {column.renderCell
                            ? column.renderCell(row, index)
                            : row[column.field]}
                        </LeftToRight>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableFooter className={styles.tableFooterRow}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={columns.length}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Box>
      </Card>
    </LocalizationProvider>
  );
};

export default UsersTable;
