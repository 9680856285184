import apiInterceptor from './Interceptors/apiInterceptor';

export const fetchCommissionSplitsService = async ({ limit, skip, id }) => {
  try {
    const response = await apiInterceptor.get(
      `/api/commissionSplits/${id}?limit=${limit}&skip=${skip}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch error logs failed');
  }
};

export const updateCommissionSplitService = async (updatedData, pagination) => {
  try {
    const response = await apiInterceptor.put(
      `/api/commissionSplits/updateCommission/${pagination?.id}?limit=${pagination?.limit}&skip=${pagination?.skip}`,
      {
        data: updatedData,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
