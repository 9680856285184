import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import styles from './mailList.module.scss';
import { formatDate } from 'utils';
import { getTime } from 'utils';

const MailList = ({ emails, selectedEmail, onSelectEmail }) => {
  return (
    <Box className={styles.mailListContainer}>
      {emails.map((email, index) => (
        <Box
          key={index}
          className={`${styles.mailItem} ${
            selectedEmail === email?.id ? styles.selected : ''
          }`}
          onClick={() => onSelectEmail(email?.id)}
        >
          <Box className={styles.emailContent}>
            <Avatar
              src={
                email?.avatarUrl ||
                'https://cdn-icons-png.flaticon.com/512/21/21104.png'
              }
              className={styles.avatar}
            />
            <Box className={styles.emailDetails}>
              <Box className={styles.emailHeader}>
                <Typography variant="subtitle1" className={styles.emailSender}>
                  {email.senderName}
                </Typography>
                <Typography variant="caption" className={styles.emailTime}>
                  {`${formatDate(email?.date)} ${getTime(email?.date)}`}
                </Typography>
              </Box>
              <Typography variant="body2" className={styles.emailSnippet}>
                {email?.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default MailList;
