import React from 'react';

const Email = () => {
  return (
    <svg
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4512 5.07129H2.54883C1.14629 5.07129 0 6.21254 0 7.62012V22.9131C0 24.3212 1.14703 25.4619 2.54883 25.4619H26.4512C27.8537 25.4619 29 24.3207 29 22.9131V7.62012C29 6.2122 27.8531 5.07129 26.4512 5.07129ZM26.0598 6.77051C25.2357 7.59729 15.5561 17.3085 15.1584 17.7075C14.8263 18.0406 14.1739 18.0409 13.8416 17.7075L2.94021 6.77051H26.0598ZM1.69922 22.6007V7.93249L9.00954 15.2666L1.69922 22.6007ZM2.94021 23.7627L10.2091 16.4701L12.6382 18.9071C13.6336 19.9057 15.3668 19.9053 16.3619 18.9071L18.7909 16.4702L26.0598 23.7627H2.94021ZM27.3008 22.6007L19.9905 15.2666L27.3008 7.93249V22.6007Z"
        fill="#687182"
      />
    </svg>
  );
};

export default Email;
