import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, IconButton, Avatar, Skeleton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { GET_EMAIL_BY_ID } from '_redux/types/mail.types';
import { mailsActions } from '_redux/slices/mail.slice';
import styles from './viewMail.module.scss';
import { formatDate, getTime } from 'utils';
import { saveAs } from 'file-saver';

const ViewMail = ({ onBack, isHistory = false, id }) => {
  const dispatch = useDispatch();
  const mail = useSelector(mailsActions.selectors.getMail);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch({ type: GET_EMAIL_BY_ID, payload: id });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (mail) {
      setLoading(false);
    }
  }, [mail]);

  const handleDownloadAttachment = () => {
    if (mail?.pdfFile) {
      // Extract the file name from the URL
      const fileName = mail.pdfFile.split('/').pop();

      // Trigger download using file-saver
      saveAs(mail.pdfFile, fileName);
    } else {
      console.error('No file available for download');
    }
  };

  return (
    <Box className={styles.viewMailContainer}>
      {!isHistory ? (
        <IconButton onClick={onBack} className={styles.backButton}>
          <ArrowBackIcon />
        </IconButton>
      ) : null}

      <Box className={styles.header}>
        {loading ? (
          <Skeleton variant="text" width={200} height={30} />
        ) : (
          <Typography variant="h6" className={styles.subject}>
            Subject: {mail?.subject || 'Email Subject'}
          </Typography>
        )}
        {loading ? (
          <Skeleton variant="text" width={150} height={20} />
        ) : (
          <Typography variant="body2" className={styles.timestamp}>
            {`${formatDate(mail?.date)} ${getTime(mail?.date)}`}
          </Typography>
        )}
      </Box>

      <Box className={styles.recipientInfo}>
        {loading ? (
          <Skeleton variant="circular" width={40} height={40} />
        ) : (
          <Avatar
            src={
              mail?.avatar ||
              'https://cdn-icons-png.flaticon.com/512/21/21104.png'
            }
            alt={mail?.receipentName || 'Recipient Avatar'}
            className={styles.avatar}
          />
        )}
        <Box>
          {loading ? (
            <>
              <Skeleton variant="text" width={100} height={20} />
              <Skeleton variant="text" width={150} height={20} />
            </>
          ) : (
            <>
              <Typography className={styles.recipientName}>
                {mail?.receipentName || 'Recipient Name'}
              </Typography>
              <Typography variant="body2" className={styles.recipientEmail}>
                {mail?.receipentEmail || 'email@domain.com'}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Box className={styles.messageContent}>
        {loading ? (
          <>
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="90%" height={60} />
            <Skeleton variant="text" width="60%" height={20} />
          </>
        ) : (
          <>
            <Typography variant="body1" className={styles.greeting}>
              Hello {mail?.receipentName || 'Recipient'},
            </Typography>
            <Typography variant="body2" className={styles.messageBody}>
              {mail?.description || 'Email content goes here...'}
            </Typography>
            <Typography variant="body2" className={styles.messageBody}>
              Looking forward and best regards,
            </Typography>
          </>
        )}
      </Box>

      {loading ? (
        <Skeleton variant="rectangular" width={120} height={40} />
      ) : (
        mail?.pdfFile && (
          <Box
            className={styles.attachmentSection}
            onClick={handleDownloadAttachment}
          >
            <InsertDriveFileIcon className={styles.attachmentIcon} />
            <Typography variant="body2" className={styles.attachmentName}>
              {mail?.pdfFile?.name || 'Attachment'}
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default ViewMail;
