import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import reducer from './slices';
import { rootSaga } from './sagas';
import localforage from 'localforage';

const persistConfig = {
  key: 'paycheck',
  whitelist: [
    'auth',
    'earlyPayments',
    'uploads',
    'profile',
    'payouts',
    'dashboard',
    'mails',
    'userManagement',
  ],
  storage: localforage,
};
const middleware = [];

const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);

const persistedReducer = persistReducer(persistConfig, reducer);

let store = null;
const getStore = () => {
  store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: false,
        immutableCheck: false,
        serializableCheck: false,
      }).concat(sagaMiddleware),
    devTools: true,
  });
  sagaMiddleware.run(rootSaga);
};

getStore();

export { getStore, store };

export const persistor = persistStore(store);
