import apiInterceptor from './Interceptors/apiInterceptor';

export const getRefundPaymentsService = async payload => {
  try {
    const response = await apiInterceptor.get(
      `/api/refundPayment?limit=${payload?.limit}&skip=${payload?.skip}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const getRefundPaymentByIdService = async payload => {
  try {
    const response = await apiInterceptor.get(
      `/api/refundPayment/${payload?.id}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const adminVerificationService = async payload => {
  try {
    const response = await apiInterceptor.post(
      `/api/refundPayment/verifyByAdmin/${payload?.id}`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
