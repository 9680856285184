import React from 'react';
import Grid from '@mui/material/Grid';
import StatItem from './StatItem/StatItem';
import { STATS_DATA } from 'utils';
import styles from './stats.module.scss';

const Stats = ({
  commission,
  pendingEarlyPayments,
  pendingRefundPayments,
  approvedEarlyPayments,
  approvedRefundPayments,
}) => {
  return (
    <Grid container spacing={2}>
      {STATS_DATA.map((stat, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2.4}
          key={index}
          className={styles.statsContainer}
        >
          <StatItem
            icon={stat.icon}
            label={stat.label}
            count={
              index === 0
                ? `£${Math.ceil(commission)}`
                : index === 1
                  ? Math.ceil(pendingEarlyPayments)
                  : index === 2
                    ? Math.ceil(pendingRefundPayments)
                    : index === 3
                      ? Math.ceil(approvedEarlyPayments)
                      : index === 4
                        ? Math.ceil(approvedRefundPayments)
                        : stat.count
            }
            backgroundColor={stat.backgroundColor}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Stats;
