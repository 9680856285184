import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styles from './loginForm.module.scss';
import { useDispatch } from 'react-redux';
import { LOGIN_USER } from '_redux/types/auth.types';
import { Link, useNavigate } from 'react-router-dom';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';
import { VerificationCode } from 'components/VerificationCode';
import { NewPassword } from 'components/NewPassword';
import { RESEND_OTP } from '_redux/types/auth.types';

const LoginForm = () => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Password visibility state
  const [check, setCheck] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const handleLogin = event => {
    event.preventDefault();
    dispatch({
      type: LOGIN_USER,
      payload: {
        email,
        password,
        onSuccess: data => {
          if (data?.role === 'admin') {
            navigate('/payment-request');
          } else {
            navigate('/');
          }
        },
        onError: error => {
          if (error === 'Your email is not verified') {
            setCheck(false);
            dispatch({
              type: RESEND_OTP,
              payload: {
                email: email,
              },
            });
            setStep(2);
            setOpen(true);
          }
        },
      },
    });
  };

  const onEmailSubmit = email => {
    setEmail(email);
    setStep(2);
  };

  const onOtpVerified = () => setStep(3);

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <Box className={styles.formContainer}>
      <h2>Login to your account</h2>
      <form className={styles.form} onSubmit={handleLogin}>
        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'} // Toggle input type
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={e => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box className={styles.passwordOptions}>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Remember Me"
          />
          <Link className={styles.forgotPassword} onClick={() => setOpen(true)}>
            Forgot Password?
          </Link>
        </Box>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#003466',
            '&:hover': {
              borderColor: 'darkred',
              backgroundColor: '#003466',
            },
          }}
        >
          Login
        </Button>
        <p>
          Not registered yet? <Link to="/signup">Create an account</Link>
        </p>
      </form>

      <ModalContainer open={open}>
        {step == 1 && (
          <ForgotPassword
            email={email}
            onSubmit={onEmailSubmit}
            onClose={handleClose}
          />
        )}
        {step == 2 && (
          <VerificationCode
            email={email}
            onVerified={onOtpVerified}
            onClose={handleClose}
            isPassword={check}
          />
        )}
        {step == 3 && <NewPassword email={email} onClose={handleClose} />}
      </ModalContainer>
    </Box>
  );
};

export default LoginForm;
