import React, { useState, useEffect, useMemo } from 'react';
import { paymentColumns } from 'utils/requests';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import EarlyPaymentRequest from 'components/EarlyPaymentRequest/EarlyPaymentRequest';
import { GenericTable } from 'components/common/GenericTable';
import { useDispatch, useSelector } from 'react-redux';
import { GET_EARLY_PAYMENTS } from '_redux/types/earlyPayments.types';
import { earlyPaymentActions } from '_redux/slices/earlyPayment.slice';
import { GET_EARLY_PAYMENT_BY_ID } from '_redux/types/earlyPayments.types';

const PayRequest = ({ handleTabChange, tab }) => {
  const dispatch = useDispatch();
  const earlyPaymentsRequests = useSelector(
    earlyPaymentActions.selectors.getEarlyPayments
  );

  const handleOpen = row => {
    dispatch({ type: GET_EARLY_PAYMENT_BY_ID, payload: { id: row?.id } });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch({ type: GET_EARLY_PAYMENTS, payload: { limit: 10, skip: 0 } });
  }, [dispatch]);

  const columns = useMemo(() => paymentColumns(handleOpen), [handleOpen]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <GenericTable
        title="Early Payment Request"
        data={earlyPaymentsRequests}
        columns={columns}
        pageConfig={useMemo(
          () => ({
            page: 0,
            setPage: () => {},
            rowsPerPage: 10,
            setRowsPerPage: () => {},
          }),
          []
        )}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
        fetchType="earlyPayments"
      />
      <ModalContainer open={open}>
        <EarlyPaymentRequest handleClose={handleClose} />
      </ModalContainer>
    </>
  );
};

export default PayRequest;
