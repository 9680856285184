import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userManagementActions } from '_redux/slices/userManagement.slice';
import { usersTableColumns } from 'utils/userManagement';
import { GET_USERS } from '_redux/types/userManagement.types';
import { CHANGE_USER_PERMISSIONS } from '_redux/types/userManagement.types';
// import { debounce } from 'lodash';
import UsersTable from 'components/common/GenericTable/UsersTable';

const UserManagement = () => {
  const dispatch = useDispatch();
  const users = useSelector(userManagementActions.selectors.getUsersFromRedux);
  const total = useSelector(userManagementActions.selectors.getTotalUsersCount);

  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleCheckboxChange = (checked, id, index, tabName) => {
    dispatch({
      type: CHANGE_USER_PERMISSIONS,
      payload: { id: id, checked: checked, tabName: tabName, index },
    });
  };

  // const handleSearchChange = useCallback(
  //   debounce(event => {
  //     // setRowsPerPage(10);
  //     // setPage(0);
  //     console.log(event.target);
  //     setSearchQuery(event.target.value);
  //   }, 1),
  //   []
  // );
  const handleSearchChange = useCallback(event => {
    setPage(0);
    setSearchQuery(event.target.value);
  }, []);

  useEffect(() => {
    dispatch({
      type: GET_USERS,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        search: searchQuery,
      },
    });
  }, [dispatch, page, rowsPerPage, searchQuery]);

  const columns = useMemo(
    () => usersTableColumns(handleCheckboxChange),
    [handleCheckboxChange]
  );

  return (
    <>
      <UsersTable
        title="Users Management"
        data={users}
        columns={columns}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: total,
        }}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
      />
    </>
  );
};

export default UserManagement;
