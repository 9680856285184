import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './mails.module.scss';
import ViewMail from 'components/ViewMail/ViewMail';
import MailList from 'components/MailList/MailList';
import { GET_ALL_MAILS } from '_redux/types/mail.types';
import { useDispatch, useSelector } from 'react-redux';
import { mailsActions } from '_redux/slices/mail.slice';

const MailsHistory = () => {
  const dispatch = useDispatch();
  const [selectedEmail, setSelectedEmail] = useState(null);
  const mails = useSelector(mailsActions.selectors.getMails);

  useEffect(() => {
    dispatch({ type: GET_ALL_MAILS });
  }, [dispatch]);

  const handleSelectEmail = id => {
    setSelectedEmail(id);
  };

  return (
    <Box className={styles.dashboardContainer}>
      <Grid container spacing={2}>
        {/* Mail List */}
        <Grid item xs={4}>
          <MailList
            emails={mails}
            selectedEmail={selectedEmail}
            onSelectEmail={handleSelectEmail}
          />
        </Grid>

        {/* View Mail Section */}
        <Grid item xs={8}>
          {selectedEmail !== null ? (
            <ViewMail
              onBack={() => setSelectedEmail(null)}
              isHistory={true}
              id={selectedEmail}
            />
          ) : (
            <Box className={styles.noMailSelected}>
              <p>Select an email to view</p>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MailsHistory;
