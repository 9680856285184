import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    address: '',
  },
  isLoading: false,
  imageLoading: false,
  error: null,
  notifications: [],
};

const name = 'profile';
const parentSelector = state => state?.[name];

const profileSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setImageLoading(state, action) {
      state.imageLoading = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const { setProfile, setLoading, setError } = profileSlice.actions;

const getProfile = createSelector(parentSelector, state => state?.profile);
const getProfileLoading = createSelector(
  parentSelector,
  state => state?.imageLoading
);
const getNotifications = createSelector(
  parentSelector,
  state => state?.notifications
);

const actions = {
  ...profileSlice.actions,
};

const selectors = {
  getProfile,
  getProfileLoading,
  getNotifications,
};

export const profileActions = { actions, selectors };

export default profileSlice;
