import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './dashboard.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_DASHBOARD_DATA } from '_redux/types/dashboard.types';
import { dashboardActions } from '_redux/slices/dashboard.slice';
import MarginChart from 'components/MarginChart/MarginChart';
import MarginStats from 'components/Stats/MarginStats';

const MarginDashboard = () => {
  const dispatch = useDispatch();
  const { companyCommission, totalAdvisors, productTypes, splitTypes } =
    useSelector(dashboardActions.selectors.selectDashboardData);

  useEffect(() => {
    dispatch({ type: FETCH_DASHBOARD_DATA });
  }, [dispatch]);

  return (
    <Box className={styles.dashboardContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MarginStats
            commission={companyCommission?.totalFCI}
            totalAdvisors={totalAdvisors?.advisorCount}
            productTypes={productTypes?.advisorCount}
            splitTypes={splitTypes?.splitCount}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MarginChart />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarginDashboard;
