import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  payouts: [],
  total: 0,
};

const name = 'payouts';
const parentSelector = state => state[name];

const payoutSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPayouts: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

const getPayouts = createSelector(parentSelector, state => state?.payouts);
const getTotalPayouts = createSelector(parentSelector, state => state?.total);

const actions = {
  ...payoutSlice.actions,
};

const selectors = {
  getPayouts,
  getTotalPayouts,
};

export const payoutActions = { actions, selectors };

export default payoutSlice;
