import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  total: 0,
};

const name = 'userManagement';
const parentSelector = state => state?.[name];

const userManagementSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setUsers: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateUserPermission: (state, action) => {
      const { checked, index, tabName } = action.payload;
      if (state?.users?.[index]) {
        const updatedUsers = state?.users?.map((user, idx) =>
          idx === index
            ? {
                ...user,
                [tabName]: checked,
              }
            : user
        );

        return {
          ...state,
          users: updatedUsers,
        };
      }
      return state; // Return the current state if index is invalid
    },
  },
});

const getUsersFromRedux = createSelector(parentSelector, state => state?.users);

const getTotalUsersCount = createSelector(
  parentSelector,
  state => state?.total
);
const getUserByIndex = createSelector(
  [parentSelector, (_, index) => index],
  (state, index) => state?.users?.[index] || null
);

const actions = {
  ...userManagementSlice.actions,
};

const selectors = {
  getUsersFromRedux,
  getTotalUsersCount,
  getUserByIndex,
};

export const userManagementActions = { actions, selectors };

export default userManagementSlice;
