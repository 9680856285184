import React from 'react';

const Margin = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8047 1.24219H18.2949V5.69531"
        stroke={color}
        stroke-miterlimit="10"
      />
      <path
        d="M17.562 1.97412L5.51074 14.0254"
        stroke={color}
        stroke-miterlimit="10"
      />
      <path
        d="M7.83008 6.91992C7.83008 6.10014 7.16549 5.43555 6.3457 5.43555C5.52592 5.43555 4.86133 6.10014 4.86133 6.91992C4.86133 7.73971 5.52592 8.4043 6.3457 8.4043C7.16549 8.4043 7.83008 7.73971 7.83008 6.91992Z"
        stroke={color}
        stroke-miterlimit="10"
      />
      <path
        d="M11.1699 13.0801C11.1699 13.8999 11.8345 14.5645 12.6543 14.5645C13.4741 14.5645 14.1387 13.8999 14.1387 13.0801C14.1387 12.2603 13.4741 11.5957 12.6543 11.5957C11.8345 11.5957 11.1699 12.2603 11.1699 13.0801Z"
        stroke={color}
        stroke-miterlimit="10"
      />
      <path
        d="M17.0029 7.17969C17.3339 8.05744 17.5156 9.00784 17.5156 10C17.5156 14.4198 13.9198 18.0156 9.5 18.0156C5.08016 18.0156 1.48437 14.4198 1.48437 10C1.48437 5.58016 5.08016 1.98438 9.5 1.98438C10.4922 1.98438 11.4426 2.16607 12.3203 2.49712V0.924013C11.4187 0.645061 10.4706 0.500002 9.5 0.500002C6.96246 0.500002 4.57677 1.48819 2.78246 3.28246C0.988186 5.07681 0 7.46246 0 10C0 12.5375 0.988186 14.9232 2.78246 16.7175C4.57677 18.5118 6.96246 19.5 9.5 19.5C12.0375 19.5 14.4232 18.5118 16.2175 16.7175C18.0118 14.9232 19 12.5375 19 10C19 9.02944 18.8549 8.08122 18.576 7.17969H17.0029Z"
        fill={color}
      />
    </svg>
  );
};

export default Margin;
