import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-hot-toast';
import { loginService, registerService } from '_redux/services/auth.service';
import { authActions } from '_redux/slices/auth.slice';
import { LOG_OUT, REGISTER_USER, LOGIN_USER } from '_redux/types/auth.types';
import { VERIFY_OTP } from '_redux/types/auth.types';
import { RESEND_OTP } from '_redux/types/auth.types';
import { resendOtpService } from '_redux/services/auth.service';
import { verifyOtpService } from '_redux/services/auth.service';
import { CHANGE_PASSWORD } from '_redux/types/auth.types';
import { changePasswordService } from '_redux/services/auth.service';
import { resetPasswordService } from '_redux/services/auth.service';
import { RESET_PASSWORD } from '_redux/types/auth.types';

export function* loginSaga(action) {
  try {
    const response = yield call(loginService, action.payload);

    if (response?.data?.accessToken) {
      yield put(
        authActions.actions.setUserSlice({
          user: response.data,
          accessToken: response?.data?.accessToken,
          resetToken: response?.data?.resetToken,
          resetTokenExpiry: new Date().getTime() + 2 * 24 * 60 * 60 * 1000,
        })
      );

      toast.success('Login successful!');
      if (action.payload.onSuccess) {
        action.payload.onSuccess(response.data);
      }
    }
  } catch (err) {
    const errorMessage = err?.message || 'Login failed. Please try again.';
    if (action.payload.onError) {
      action.payload.onError(err?.message);
    }
    toast.error(errorMessage);
  }
}

export function* registerSaga(action) {
  try {
    const user = yield call(registerService, action.payload);

    if (user) {
      // yield put(authActions.actions.setUserSlice(user?.data));

      toast.success('Registration successful!');

      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage =
      err?.response?.data?.message || 'Registration failed. Please try again.';
    toast.error(errorMessage);
  }
}

export function* logoutSaga() {
  try {
    yield put(authActions.actions.resetAuth());
    toast.success('Logged out successfully.');
  } catch (err) {
    console.log('Logout error', err);
  }
}

export function* verifyOtpSaga(action) {
  try {
    const response = yield call(verifyOtpService, action.payload);
    if (response?.status === 200) {
      toast.success('OTP Verified successfully!');
      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage = err?.response?.data?.message || 'Verification failed';
    toast.error(errorMessage);
  }
}

export function* resendOtpSaga(action) {
  try {
    yield toast
      .promise(resendOtpService(action.payload), {
        loading: 'Sending OTP...',
        success: 'OTP sent successfully!',
        error: 'Failed to send OTP, please try again.',
      })
      .then(response => {
        if (response?.status === 200) {
          if (action.payload.onSuccess) {
            action.payload.onSuccess();
          }
        }
      })
      .catch(error => {
        console.log('Error sending OTP:', error);
      });
  } catch (error) {
    console.error('Saga error during OTP resend:', error);
  }
}

export function* changePasswordSaga(action) {
  try {
    const response = yield call(changePasswordService, action.payload);

    if (response?.status === 200) {
      toast.success('Password changed successfully!');
      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage =
      err?.response?.data?.message ||
      'Password change failed. Please try again.';
    toast.error(errorMessage);
  }
}

export function* resetPasswordSaga(action) {
  try {
    const response = yield call(resetPasswordService, action.payload);

    if (response?.status === 200) {
      toast.success('Password changed successfully!');
      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage =
      err?.message || 'Password change failed. Please try again.';
    toast.error(errorMessage);
  }
}

export function* watchAuthAsync() {
  yield takeEvery(LOGIN_USER, loginSaga);
  yield takeEvery(REGISTER_USER, registerSaga);
  yield takeEvery(LOG_OUT, logoutSaga);
  yield takeEvery(VERIFY_OTP, verifyOtpSaga);
  yield takeEvery(RESEND_OTP, resendOtpSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
}
