import React from 'react';

const deleteIcon = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.799072 5.27539V10.2156C0.871726 11.16 0.944373 12.395 1.01703 13.2668C1.08967 14.2839 1.96147 15.0831 2.97856 15.0831H8.3546C9.3717 15.0831 10.2435 14.2839 10.3162 13.2668C10.3888 12.395 10.4615 11.16 10.5341 10.2156C10.6067 9.12578 10.5341 6.87364 10.5341 5.27539H0.799072Z"
        fill="#687182"
      />
      <path
        d="M10.7521 3.0955H8.93591L8.35472 2.00576C7.99149 1.35192 7.33763 0.916016 6.61109 0.916016H4.86753C4.14102 0.916016 3.41453 1.35192 3.12393 2.00576L2.39744 3.0955H0.581195C0.290601 3.0955 0 3.3861 0 3.6767C0 3.9673 0.290601 4.2579 0.581195 4.2579H10.7521C11.0427 4.2579 11.3333 4.03995 11.3333 3.6767C11.3333 3.31346 11.0427 3.0955 10.7521 3.0955ZM3.63248 3.0955L3.99573 2.51431C4.14102 2.22371 4.43162 2.00576 4.79485 2.00576H6.53848C6.90172 2.00576 7.19228 2.15106 7.33763 2.51431L7.70086 3.0955H3.63248Z"
        fill="#687182"
      />
    </svg>
  );
};

export default deleteIcon;
