import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Close,
  AttachFile as AttachFileIcon,
  InsertDriveFile as FileIcon,
} from '@mui/icons-material';
import styles from './composeMail.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_MAIL } from '_redux/types/mail.types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { authActions } from '_redux/slices/auth.slice';

const ComposeMail = ({ onClose, file }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(authActions.selectors.getUser);
  const [attachment, setAttachment] = useState(file);

  // Available recipient emails
  const availableEmails = ['admin@gmail.com'];

  const validationSchema = Yup.object({
    to: Yup.string()
      .email('Invalid email address')
      .required('Recipient email is required'),
    subject: Yup.string().required('Subject is required'),
    content: Yup.string().required('Email content is required'),
  });

  const initialValues = {
    to: 'admin@gmail.com', // Default selected email
    subject: '',
    content: '',
  };

  const handleSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    formData.append('receipentName', values.to.split('@')[0]);
    formData.append('senderName', `${user.firstName} ${user?.lastName}`);
    formData.append('senderEmail', user.email);
    formData.append('receipentEmail', values.to);
    formData.append('subject', values.subject);
    formData.append('heading', values.subject);
    formData.append('description', values.content);
    formData.append('footer', 'Looking forward to hearing from you');

    if (attachment) {
      formData.append('file', attachment);
    }

    dispatch({ type: SEND_MAIL, payload: formData });
    resetForm();
    setAttachment(null);
    onClose();
  };

  const handleFileChange = event => {
    setAttachment(event.target.files[0]);
  };

  const handleRemoveFile = () => {
    setAttachment(null);
  };

  return (
    <Box className={styles.composeMailContainer}>
      <Box className={styles.header}>
        <Typography variant="h6" className={styles.title}>
          Compose Email
        </Typography>
        <IconButton onClick={onClose} className={styles.closeButton}>
          <Close />
        </IconButton>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form>
            <Box className={styles.body}>
              <TextField
                fullWidth
                variant="outlined"
                label="From"
                value={user.email}
                className={styles.inputField}
                // disabled
              />

              <Select
                fullWidth
                variant="outlined"
                // label="To"
                name="to"
                value={values.to}
                onChange={event => setFieldValue('to', event.target.value)}
                className={styles.inputField}
                error={touched.to && !!errors.to}
              >
                {availableEmails.map(email => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
              {touched.to && errors.to && (
                <Typography className={styles.errorText}>
                  {errors.to}
                </Typography>
              )}

              <TextField
                fullWidth
                variant="outlined"
                label="Subject"
                name="subject"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.subject}
                className={styles.inputField}
                error={touched.subject && !!errors.subject}
                helperText={touched.subject && errors.subject}
              />

              <TextField
                fullWidth
                variant="outlined"
                label="Content"
                name="content"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.content}
                multiline
                rows={6}
                className={styles.textArea}
                error={touched.content && !!errors.content}
                helperText={touched.content && errors.content}
              />
              <Box className={styles.attachmentContainer}>
                {attachment?.name && (
                  <Box className={styles.attachmentPreview}>
                    <FileIcon />
                    <Typography className={styles.fileName}>
                      {attachment?.name}
                    </Typography>
                    <IconButton onClick={handleRemoveFile}>
                      <Close />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>

            <Box className={styles.footer}>
              <Box className={styles.iconsContainer}>
                <label htmlFor="file">
                  <input
                    type="file"
                    id="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <IconButton component="span">
                    <AttachFileIcon />
                  </IconButton>
                </label>
              </Box>

              <Button
                type="submit"
                variant="contained"
                className={styles.submitButton}
              >
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ComposeMail;
