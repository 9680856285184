import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styles from './signupForm.module.scss';
import { VerificationCode } from 'components/VerificationCode';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { useDispatch } from 'react-redux';
import { REGISTER_USER } from '_redux/types/auth.types';
import { Link } from 'react-router-dom';

const SignupForm = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Password visibility state

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRegister = event => {
    event.preventDefault();

    const payload = {
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      phoneNo: event.target.phone.value,
      password: event.target.password.value,
      onSuccess: () => {
        setEmail(event.target.email.value);
        handleOpen();
      },
    };

    dispatch({ type: REGISTER_USER, payload });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <Box className={styles.formContainer}>
      <h2>Create an account</h2>
      <form className={styles.form} onSubmit={handleRegister}>
        <TextField
          label="First Name"
          name="firstName"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Last Name"
          name="lastName"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email Address"
          name="email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Phone Number"
          name="phone"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          name="password"
          type={showPassword ? 'text' : 'password'} // Toggle input type
          variant="outlined"
          fullWidth
          margin="normal"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <p className={styles.terms}>
          By continuing, you agree to Paycheck's{' '}
          <Link to="#">Terms & Conditions</Link> and{' '}
          <Link to="#">Privacy Policy</Link>.
        </p>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className={styles.submitButton}
        >
          Sign Up
        </Button>

        <p className={styles.loginLink}>
          Already a member? <Link to="/login">Login</Link>
        </p>
      </form>
      <ModalContainer open={open}>
        <VerificationCode email={email} onClose={handleClose} />
      </ModalContainer>
    </Box>
  );
};

export default SignupForm;
