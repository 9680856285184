import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  accessToken: '',
  resetToken: '',
  resetTokenExpiry: null,
  isAuthenticated: false,
};

const name = 'auth';
const parentSelector = state => state?.[name];

const authSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    resetAuth: () => initialState,
    loginUserStatus: (state, action) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
    setUserSlice: (state, action) => {
      return {
        ...state,
        user: action.payload,
        accessToken: action.payload.accessToken,
        resetToken: action.payload.resetToken,
        resetTokenExpiry: action.payload.resetTokenExpiry,
        isAuthenticated: !!action.payload,
      };
    },
    setProfileSlice: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setProfileImageSlice: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            profileImageUrl: action.payload,
          },
        },
      };
    },
  },
});

const getToken = createSelector(parentSelector, state => state?.accessToken);
const getUser = createSelector(parentSelector, state => state?.user);
const getResetTokenExpiry = createSelector(
  parentSelector,
  state => state?.resetTokenExpiry
);
const getIsAuthenticated = createSelector(
  parentSelector,
  state => state?.isAuthenticated
);

const actions = {
  ...authSlice.actions,
};

const selectors = {
  getToken,
  getUser,
  getIsAuthenticated,
  getResetTokenExpiry,
};

export const authActions = { actions, selectors };

export default authSlice;
