import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authActions } from '_redux/slices/auth.slice';
import { store } from '_redux/store';

const ProtectedRoute = ({ tabName, children }) => {
  const isAuthenticated = useSelector(authActions.selectors.getIsAuthenticated);
  const { user } = useSelector(authActions.selectors.getUser);
  const state = store.getState();
  const token = state?.auth?.accessToken;

  if (!token) {
    return <Navigate to="/login" />;
  }
  if (user?.role === 'manager' && tabName) {
    if (!user?.[tabName]) {
      // Redirect to the "Permission Denied" page if permission is not granted
      return <Navigate to="/permission-denied" />;
    }
  }

  if (!isAuthenticated && user?.role !== 'admin') {
    return <Navigate to="/login" />;
  } else if (user?.role === 'admin') {
    return <Navigate to="/payment-request" />;
  }

  return children;
};

export default ProtectedRoute;
