import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Box } from '@mui/material';
import styles from './salesChart.module.scss';
import { CHART_OPTIONS } from 'utils';
import CardHeader from './CardHeader/CardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_SALES_CHART } from '_redux/types/dashboard.types';
import dayjs from 'dayjs';

const SalesChart = () => {
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const salesChartData = useSelector(state => state?.dashboard?.totalSales);

  const chartCategories = useMemo(() => {
    return (
      salesChartData?.gross?.map((item, index) => `Week ${index + 1}`) || []
    );
  }, [salesChartData]);

  const chartSeries = useMemo(
    () => salesChartData?.gross?.map(item => Object.values(item)?.[0]) || [],
    [salesChartData]
  );

  useEffect(() => {
    const month = selectedMonth.month();
    const year = selectedMonth.year();
    dispatch({
      type: FETCH_SALES_CHART,
      payload: {
        month: typeof month === 'number' ? month : dayjs().month(),
        year: typeof year === 'number' ? year : dayjs().year(),
      },
    });
  }, [dispatch, selectedMonth]);

  const updatedChartOptions = useMemo(
    () => ({
      ...CHART_OPTIONS,
      xaxis: {
        ...CHART_OPTIONS.xaxis,
        categories: chartCategories, // Updated to show weeks
      },
      yaxis: {
        ...CHART_OPTIONS.yaxis,
        min: 0,
        max: Math.max(...chartSeries), // Dynamically adjust Y-axis max value
      },
    }),
    [chartCategories, chartSeries]
  );

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 0 }} className={styles.card}>
      <CardHeader
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        series={chartSeries}
      />
      <Box>
        <Chart
          options={updatedChartOptions}
          series={[{ name: 'Sales', data: chartSeries }]}
          type="area"
          height={308}
        />
      </Box>
    </Card>
  );
};

export default SalesChart;
