import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { dataColumns } from 'utils/uploadData';
import { useDispatch, useSelector } from 'react-redux';
import { GET_FILES, DELETE_FILE } from '_redux/types/files.types';
import { filesActions } from '_redux/slices/files.slice';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';

const UploadData = ({ onUploadClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch({
      type: GET_FILES,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
    });
  }, [dispatch, page, rowsPerPage]);

  const files = useSelector(filesActions.selectors.getFiles);
  const totalFiles = useSelector(filesActions.selectors.getTotalFiles);

  const handleOpenConfirm = id => {
    setDeleteId(id);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setDeleteId(null);
  };

  const handleDeleteConfirm = () => {
    dispatch({
      type: DELETE_FILE,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        id: deleteId,
      },
    });
    handleCloseConfirm();
  };

  return (
    <>
      <GenericTable
        title="Data Upload"
        data={files}
        columns={dataColumns(navigate, handleOpenConfirm)}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalFiles,
        }}
        onUploadClick={onUploadClick}
        isMain={true}
        isDownload={false}
        isUpload={true}
      />

      <Dialog open={openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle sx={{ color: '#003466' }}>
          Are you sure you want to delete?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleCloseConfirm}
            variant="outlined"
            sx={{ color: '#747475', borderColor: '#003466' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            sx={{ backgroundColor: '#003466' }}
          >
            Yes, Sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadData;
