import React, { useEffect } from 'react';
import Greeting from './Greeting/Greeting';
import IconButtons from './IconButtons/IconButtons';
import styles from './header.module.scss';
import { authActions } from '_redux/slices/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { GET_NOTIFICATIONS } from '_redux/types/profile.types';

const Header = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(authActions.selectors.getUser);

  useEffect(() => {
    dispatch({ type: GET_NOTIFICATIONS });
  }, [dispatch]);

  return (
    <div className={styles.headerContainer}>
      <Greeting name="Smith" />
      <div className={styles.iconContainer}>
        <IconButtons />
        <Avatar
          src={user?.profileImageUrl}
          className={styles.avtar}
          alt="Profile Picture"
        />
      </div>
    </div>
  );
};

export default Header;
