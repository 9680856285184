import { Box, Typography } from '@mui/material';

const Index = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100vh',
      }}
    >
      <Box>
        <Box>
          <Typography variant="h3" paragraph>
            No permission
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ color: 'text.secondary' }}>
            The page you&apos;re trying access has restricted access.
            <br />
            Please refer to your system administrator
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
