import apiInterceptor from './Interceptors/apiInterceptor';

export const updateProfileService = async payload => {
  try {
    const response = await apiInterceptor.put(
      `/api/user/updateProfile/${payload.id}`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const uploadProfileImageService = async payload => {
  const formData = new FormData();
  formData.append('file', payload?.file);
  formData.append('email', payload?.email);
  return await apiInterceptor.put(
    `api/user/updateProfile/${payload?.id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const getNotificationService = async () => {
  try {
    const response = await apiInterceptor.get(`/api/dashboard/notifications`);
    return response;
  } catch (error) {
    throw new Error(error?.message || 'Server Error');
  }
};

export const viewNotificationsService = async payload => {
  try {
    const response = await apiInterceptor.put(
      `/api/dashboard/updateNotifications`,
      { data: payload }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
