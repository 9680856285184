import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './mails.module.scss';
import { EMails } from 'components/Mails';
import ViewMail from 'components/ViewMail/ViewMail';

const Mails = () => {
  const [view, setView] = useState(false);
  const [id, setId] = useState(null);

  const renderContent = () => {
    return <EMails setView={setView} setId={setId} />;
  };

  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {view ? (
              <ViewMail onBack={() => setView(false)} id={id} />
            ) : (
              renderContent()
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Mails;
