import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { useMemo } from 'react';
import { formatToTwoDecimals } from 'utils';
import { formatDateToMonthYear } from 'utils';
import { formatDate } from 'utils';

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 120px 24px',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          padding: '8px 0',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '5%',
        },
        tableCell_2: {
          width: '50%',
          paddingRight: 16,
        },
        tableCell_3: {
          width: '15%',
        },
      }),
    []
  );

const PdfViewerModal = ({ advisorData, user, date }) => {
  const styles = useStyles();
  const expenses = advisorData?.advisor?.expensesArray
    ? JSON.parse(advisorData?.advisor?.expensesArray)
    : [];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <Image
            source="https://citionline.co.za/wp-content/uploads/paycheck-logo-af2a488b3de301547fb1cc010860db5b-1.png"
            style={{ width: 48, height: 48 }}
            alt="logo"
          />

          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h3}>Report Period</Text>
            <Text>
              {' '}
              {advisorData?.period} | {formatDateToMonthYear(date)}
            </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>Report From</Text>
            <Text style={styles.body2}>PayCheck</Text>
            <Text style={styles.body2}>
              {user?.firstName} {user?.lastName}
            </Text>
            <Text style={styles.body2}>{user?.email}</Text>
            <Text style={styles.body2}>{user?.phoneNo}</Text>
          </View>

          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}> To</Text>
            <Text style={styles.body2}>{advisorData?.advisorId}</Text>
            <Text style={styles.body2}>{advisorData?.advisorName}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>Date create</Text>
            <Text style={styles.body2}>{formatDate(new Date())}</Text>
          </View>
        </View>

        <Text style={[styles.subtitle1, styles.mb8]}>Details</Text>

        <View style={styles.table}>
          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Transaction ID</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Gross FCI</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>FCI Recognition</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Advisor Split Amount</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Split Percentage</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Date</Text>
              </View>
            </View>
          </View>

          <View>
            {advisorData?.advisor?.advisorDetails?.map((item, index) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item?.transactionID}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item?.grossFCI}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item?.FCIRecognition}</Text>
                </View>

                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{item?.advisorSplitAmount}</Text>
                </View>

                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{item?.advisorSplitPercentage}</Text>
                </View>

                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{formatDate(item?.date)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <Text style={[styles.subtitle1, styles.mb40]}></Text>
        <Text style={[styles.subtitle1, styles.mb8]}>Expenses Details</Text>

        <View style={styles.table}>
          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Name</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Amount</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Date</Text>
              </View>
            </View>
          </View>

          <View>
            {expenses.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={styles.tableCell_2}>
                  <Text>{item.name}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text>£{formatToTwoDecimals(item.amount)}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text>{formatDate(item.date)}</Text>
                </View>
              </View>
            ))}
          </View>

          <Text style={[styles.subtitle1, styles.mb40]}></Text>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Balance</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>
                £
                {formatToTwoDecimals(advisorData?.advisor?.advisorBalance) || 0}
              </Text>
            </View>
          </View>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Payment / Claw Back</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>
                £{formatToTwoDecimals(advisorData?.advisor?.payAways) || 0}
              </Text>
            </View>
          </View>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Loan</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>
                £{formatToTwoDecimals(advisorData?.advisor?.loanRepayment) || 0}
              </Text>
            </View>
          </View>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Expenses</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>
                £{formatToTwoDecimals(advisorData?.advisor?.expenses) || 0}
              </Text>
            </View>
          </View>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Deductions</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>
                £{formatToTwoDecimals(advisorData?.totalDeduction) || 0}
              </Text>
            </View>
          </View>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Early Payments</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>
                £{formatToTwoDecimals(advisorData?.advisor?.advances) || 0}
              </Text>
            </View>
          </View>

          {/* <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Margin</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>£{formatToTwoDecimals(advisorData?.LgMargin) || 0}</Text>
            </View>
          </View> */}

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text style={styles.h4}>Net Payout</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text style={styles.h4}>
                £{formatToTwoDecimals(advisorData?.netPayout) || 0}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfViewerModal;
