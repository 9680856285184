import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import styles from './earlyPaymentRequest.module.scss';
import { GenericTable } from 'components/common/GenericTable';
import { EARLY_PAY_REQUEST_COLUMNS } from 'utils/requests';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { earlyPaymentActions } from '_redux/slices/earlyPayment.slice';
import { formatDate } from 'utils';
import { EARLY_PAYMENT_ADMIN_VERIFICATION } from '_redux/types/earlyPayments.types';

const EarlyPaymentRequest = ({ handleClose }) => {
  const [adminNote, setAdminNote] = useState('');
  const selectedEarlyPayment = useSelector(
    earlyPaymentActions.selectors.getSelectedEarlyPayments
  );
  const dispatch = useDispatch();

  const earlyPayment = selectedEarlyPayment?.earlyPayment || {};
  const employeeHistory = selectedEarlyPayment?.employeeHistory || [];

  const formattedRequestDate = useMemo(
    () => formatDate(earlyPayment.requestDate),
    [earlyPayment.requestDate]
  );

  const handleApproval = status => {
    dispatch({
      type: EARLY_PAYMENT_ADMIN_VERIFICATION,
      payload: { id: earlyPayment?.id, status: status, note: adminNote },
    });
    handleClose();
  };

  return (
    <Box className={styles.earlyPaymentContainer}>
      <Box className={styles.heading}>
        <Typography variant="h6" className={styles.title}>
          Early Payment Request
        </Typography>
        <IconButton onClick={handleClose} className={styles.closeButton}>
          <Close />
        </IconButton>
      </Box>

      <Box className={styles.formContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Advisor Name"
              variant="outlined"
              value={earlyPayment?.advisorName || ''}
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Advisors ID"
              variant="outlined"
              value={earlyPayment?.advisorId || ''}
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Total Commission Earned"
              variant="outlined"
              value={earlyPayment.totalCommission || ''}
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Reason For Early Payment"
              variant="outlined"
              value={earlyPayment.reason || ''}
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Request Date"
              variant="outlined"
              value={formattedRequestDate || ''}
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Admin Note"
              variant="outlined"
              value={adminNote || earlyPayment?.note}
              onChange={e => setAdminNote(e.target.value)}
              className={styles.textField}
            />
          </Grid>
        </Grid>

        <Box className={styles.tableContainer}>
          <Typography variant="h6" className={styles.tableTitle}>
            Request History
          </Typography>
          <GenericTable
            title=""
            data={employeeHistory}
            columns={EARLY_PAY_REQUEST_COLUMNS}
            pageConfig={{
              page: 0,
              setPage: () => {},
              rowsPerPage: 10,
              setRowsPerPage: () => {},
            }}
            isTabs={false}
            isSearch={false}
            isPagination={false}
          />
        </Box>

        <Box className={styles.actionButtons}>
          <Button
            variant="outlined"
            className={styles.rejectButton}
            onClick={() => handleApproval('Rejected')}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            className={styles.acceptButton}
            onClick={() => handleApproval('Approved')}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EarlyPaymentRequest;
