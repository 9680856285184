import { Button } from '@mui/material';
import { Visibility, Download, Share } from '@mui/icons-material';
import { formatDate } from 'utils';

export const REPORTS_DATA = [
  {
    id: 1,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 2,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 3,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 4,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 5,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 6,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 7,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
];

export const reportColumns = (
  handleViewAdvisor,
  handleDownload,
  handleShare
) => [
  {
    headerName: 'Advisor Name',
    field: 'advisorName',
  },
  {
    headerName: 'Advisor ID',
    field: 'advisorId',
    renderCell: row => row?.advisorId,
  },
  {
    headerName: 'Advisory Date',
    field: 'date',
    renderCell: row => formatDate(row?.advisor?.advisorDetails?.[0]?.date),
  },
  {
    headerName: 'View',
    renderCell: row => (
      <Button
        variant="text"
        onClick={() => handleViewAdvisor(row)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Visibility sx={{ color: '#687182' }} />
      </Button>
    ),
  },
  {
    headerName: 'Download',
    renderCell: row => (
      <Button
        variant="text"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleDownload(row)}
      >
        <Download sx={{ color: '#687182' }} />
      </Button>
    ),
  },
  {
    headerName: 'Share',
    renderCell: row => (
      <Button
        variant="text"
        onClick={() => handleShare(row)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Share sx={{ color: '#687182' }} />
      </Button>
    ),
  },
];
