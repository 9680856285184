import apiInterceptor from './Interceptors/apiInterceptor';

export const getMailsService = async () => {
  try {
    const response = await apiInterceptor.get(`/api/emailHistory`);
    return response;
  } catch (error) {
    throw new Error(error?.message || 'Server Error');
  }
};

export const sendMailService = async payload => {
  try {
    const response = await apiInterceptor.post(`/api/emailHistory`, payload);
    return response;
  } catch (error) {
    throw new Error(error?.message || 'Server Error');
  }
};

export const getMailByIdService = async id => {
  try {
    const response = await apiInterceptor.get(`/api/emailHistory/${id}`);
    return response;
  } catch (error) {
    throw new Error(error?.message || 'Server Error');
  }
};
