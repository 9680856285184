import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authActions } from '_redux/slices/auth.slice';
import { store } from '_redux/store';

const ProtectedRouteAdmin = ({ children }) => {
  const { user } = useSelector(authActions.selectors.getUser);
  const isAuthenticated = useSelector(authActions.selectors.getIsAuthenticated);
  const state = store.getState();
  const token = state?.auth?.accessToken;

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (!isAuthenticated || user?.role !== 'admin') {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRouteAdmin;
