import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  mails: [],
  mail: {},
};

const name = 'mails';
const parentSelector = state => state?.[name];

const mailsSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setMails: (state, action) => {
      return {
        ...state,
        mails: action.payload,
        mail: null,
      };
    },
    setMail: (state, action) => {
      return {
        ...state,
        mail: action.payload,
      };
    },
  },
});

const getMails = createSelector(parentSelector, state => state?.mails);
const getMail = createSelector(parentSelector, state => state?.mail);

const actions = {
  ...mailsSlice.actions,
};

const selectors = {
  getMails,
  getMail,
};

export const mailsActions = { actions, selectors };

export default mailsSlice;
