import dayjs from 'dayjs';
import apiInterceptor from './Interceptors/apiInterceptor';

export const fetchPendingEarlyPaymentsService = async () => {
  return await apiInterceptor.get('/api/dashboard/pendingEarlyPayment');
};

export const fetchApprovedEarlyPaymentsService = async () => {
  return await apiInterceptor.get('/api/dashboard/approvedEarlyPayment');
};

export const fetchPendingRefundPaymentsService = async () => {
  return await apiInterceptor.get('/api/dashboard/pendingRefundPayment');
};

export const fetchApprovedRefundPaymentsService = async () => {
  return await apiInterceptor.get('/api/dashboard/approvedRefundPayment');
};

export const fetchCompanyCommissionService = async () => {
  return await apiInterceptor.get('/api/dashboard/companyCommission');
};

export const fetchTopPlansService = async () => {
  return await apiInterceptor.get('/api/dashboard/topPlans');
};

export const fetchTopAdvisorsService = async () => {
  return await apiInterceptor.get('/api/dashboard/topAdvisors');
};

export const fetchTotalAdvisorsService = async () => {
  return await apiInterceptor.get('/api/dashboard/totalAvisor');
};

export const fetchProductTypesService = async () => {
  return await apiInterceptor.get('/api/dashboard/productsType');
};

export const fetchSplitTypesService = async () => {
  return await apiInterceptor.get('/api/dashboard/splitType');
};

export const fetchAdvisorBaseService = async payload => {
  return await apiInterceptor.get(
    `/api/dashboard/advisorBase?productType=${payload?.productType || ''}&month=${payload?.month - 1 || dayjs().month()}&year=${payload?.year || dayjs().year()}&advisor=${payload?.advisor || ''}&commissionType=${payload?.commissionType || ''}`
  );
};

export const fetchTotalSalesService = async payload => {
  return await apiInterceptor.post(
    `/api/dashboard/totalSale?month=${payload?.month || dayjs().month()}&year=${payload?.year || dayjs().year()}`,
    payload
  );
};

export const fetchAdvisorsService = async () => {
  return await apiInterceptor.get('/api/dashboard/allAdvisors');
};
export const fetchProductsTypesService = async () => {
  return await apiInterceptor.get('/api/dashboard/allProducts');
};
export const fetchCommissionTypesService = async () => {
  return await apiInterceptor.get('/api/dashboard/allCommissionTypes');
};
export const exportMarginService = async payload => {
  return await apiInterceptor.get(
    `/api/dashboard/downloadAdvisorBase?productType=${payload?.productType || ''}&month=${payload?.month - 1 || dayjs().month()}&year=${payload?.year || dayjs().year()}&advisor=${payload?.advisor || ''}&commissionType=${payload?.commissionType || ''}`
  );
};
