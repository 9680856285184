import React, { useState } from 'react';
import { CardHeader, Typography } from '@mui/material';
import Animation from 'utils/Animation';
import styles from './cardHeader.module.scss';
import { calculateTotal } from 'utils';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { selectStyles } from 'utils/styles';

const _CardHeader = ({ series, selectedMonth, setSelectedMonth }) => {
  const [isPickerOpen, setPickerOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CardHeader
        title={
          <Animation>
            <Typography variant="h5" fontWeight="bold" className={styles.title}>
              £{calculateTotal(series)}
            </Typography>
          </Animation>
        }
        subheader={
          <Animation delay={0.3}>
            <Typography variant="body2" color="textSecondary">
              Total Commission Amount
            </Typography>
          </Animation>
        }
        action={
          <Animation>
            <MobileDatePicker
              open={isPickerOpen}
              onOpen={() => setPickerOpen(true)}
              onClose={() => setPickerOpen(false)}
              views={['year', 'month']}
              label="Select Month"
              minDate={dayjs('2022-01-01')}
              maxDate={dayjs('2030-12-31')}
              value={selectedMonth}
              onChange={newDate => setSelectedMonth(newDate)}
              onAccept={newDate => {
                setSelectedMonth(newDate);
                setPickerOpen(false);
              }}
              DialogProps={{
                keepMounted: true,
              }}
              sx={{
                ...selectStyles,
                '& .MuiInputBase-root': {
                  height: '40px',
                  fontSize: '1rem',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '0.5rem',
                },
                borderRadius: '0.5rem',
                backgroundColor: '#f5f7fa',
                width: '10rem',
              }}
            />
          </Animation>
        }
      />
    </LocalizationProvider>
  );
};

export default _CardHeader;
