import React from 'react';

const UserManagement = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 24 24"
    >
      <g fill="none" stroke={color} stroke-linecap="round">
        <path d="M19.727 20.447c-.455-1.276-1.46-2.403-2.857-3.207S13.761 16 12 16s-3.473.436-4.87 1.24s-2.402 1.931-2.857 3.207" />
        <circle cx="12" cy="8" r="4" />
      </g>
    </svg>
  );
};

export default UserManagement;
